import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateBoardContents = ({
  boardIdx,
  publishYn,
  noticeYn,
  title,
  contents,
  files,
  errors,
  onChangeFieldValue,
  onChangeContents,
  onChangeFiles,
  onRemoveFiles,
  onCreateBoard,
}) => {
  const fileRef = useRef();

  return (
    <div className="card card-border-top w-full mt-20 mb-20">
      <div className="editor__header">
        <span>게시글 등록</span>
      </div>
      <div className="divider"></div>
      <div className="editor__main">
        <div className="form-group flex-direction-row align-items-center mb-20">
          <label>게시판</label>
          <select
            className="select-box"
            id="boardIdx"
            name="boardIdx"
            value={boardIdx}
            onChange={onChangeFieldValue}
          >
            <option value={1}>새소식</option>
          </select>
        </div>
        <div className="d-flex align-items-center mb-10">
          <div className="form-group flex-direction-row align-items-center mb-0">
            <label>게시여부</label>
            <select
              className="select-box"
              id="publishYn"
              name="publishYn"
              value={publishYn}
              onChange={onChangeFieldValue}
            >
              <option value="Y">공개</option>
              <option value="N">비공개</option>
            </select>
          </div>
          <div className="form-group flex-direction-row align-items-center mb-0 mt-0">
            <label>공지여부</label>
            <select
              className="select-box"
              id="noticeYn"
              name="noticeYn"
              value={noticeYn}
              onChange={onChangeFieldValue}
            >
              <option value="Y">공지</option>
              <option value="N">일반</option>
            </select>
          </div>
        </div>
        <div className="mb-10">
          {" "}
          <div className="form-group flex-direction-row align-items-center">
            <label>제목</label>
            <input
              className={"form-control"}
              type="text"
              name="title"
              placeholder="제목을 입력해주세요."
              value={title}
              onChange={onChangeFieldValue}
            />
          </div>
          <p className="validation-message ml-90 mb-10">{errors.title}</p>
        </div>
        <div className="mb-20">
          {" "}
          <div className="form-group flex-direction-row align-items-center">
            <label>내용</label>
            <CKEditor
              editor={ClassicEditor}
              data={contents}
              onChange={(event, editor) => {
                const data = editor.getData();
                onChangeContents(data);
              }}
            />
          </div>
          <p className="validation-message ml-90 mb-10">{errors.contents}</p>
        </div>
        <div className="attach-file">
          {" "}
          {files &&
            files.length > 0 &&
            files.map((file, i) => (
              <div
                key={`create-file-${i}`}
                className="attach-file__item border-warning"
              >
                {file.name}
                <em
                  className="fa fa-times ml-5"
                  onClick={() => onRemoveFiles(file)}
                ></em>
              </div>
            ))}
        </div>

        <div className="form-group flex-direction-row align-items-center">
          <label>첨부파일</label>
          <input
            ref={fileRef}
            className={"form-control w-one-three"}
            type="file"
            name="file"
            onChange={(e) => {
              onChangeFiles(e);
              fileRef.current.value = "";
            }}
          />
        </div>
      </div>

      <div className="divider"></div>
      <div className="editor__footer">
        <div>
          <Link to="/board/boardContents">
            <button className="list-btn">
              <em className="fas fa-list-ul mr-5"></em>
              <strong>목록</strong>
            </button>
          </Link>
        </div>

        <div>
          <button className={`btn btn-s`} onClick={onCreateBoard}>
            <em className="fa fa-check mr-5"></em>
            <strong>게시글 등록</strong>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateBoardContents;
