import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-contents">
        <ul className="footer-contents__link">
          <li>
            <Link to="/help/guides">
              <span>이용안내</span>
            </Link>
          </li>
        </ul>
        <div className="footer-contents__company">
          <p className="name">
            (주)워트인텔리전스 <span>사업자등록번호 675-86-00133</span>
          </p>
          <p className="addr">
            서울시 서초구 강남대로 507, 6층 대표 윤정호
            <span>통신판매업신고 2019-서울서초-1670</span>
          </p>
          <p>문의 02-521-0110 (ARS 3번) / cs@ipkingkong.com (평일 9:30 ~ 18:00)</p>
          <p>
            Copyright © 2023 IP KINGKONG (WERT Intelligence x SYSTRAN).
            <span>All rights reserved.</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
