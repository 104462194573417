import React from "react";

import "./CommonModal.scss";

const CommonModal = ({
  id,
  isOpen,
  title,
  className,
  style,
  body,
  footer,
  onClickCloseModal,
}) => {
  return (
    <div
      key={id}
      id={id}
      className={"common-modal"}
      style={{ display: isOpen ? "flex" : "none" }}
    >
      <section className="common-modal-contents" style={{ ...style }}>
        <div className={`common-modal-contents__header ${className}`}>
          <span>{title}</span>
          <div
            className="common-modal-contents__close-btn"
            onClick={onClickCloseModal}
          >
            <i className="fas fa-times" />
          </div>
        </div>
        <div className="common-modal-contents__main">{body}</div>
        <div className={"common-modal-contents__footer"}>{footer}</div>
      </section>
    </div>
  );
};

export default CommonModal;
