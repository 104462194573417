import { convertMonthFormat, httpRequest } from "@/util/common";
import React, { Component } from "react";
import PaymentMonthMngList from "./PaymentMonthMngList";

class PaymentMonthMngListContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.state = {
      userEmail: "",
      userName: "",
      paymentMonthMngList: [],
      fromDate: d,
      endDate: new Date(),
      postsPerPage: 6,
      total: 0,
    };
  }
  
  componentDidMount() {
    this.fetchPaymentMonthMngList(1);
  }

  render() {
    const { userEmail, userName, paymentMonthMngList, postsPerPage, total, fromDate, endDate } = this.state;
    return (
      <PaymentMonthMngList
        monthTermList={this.monthTermList}
        onChangeMonthTerm={this.handleMonthTerm}
        fromDate={fromDate}
        endDate={endDate}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        userEmail={userEmail}
        userName={userName}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={paymentMonthMngList}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchPaymentMonthMngList}
      />
    );
  }

  /**
   * 결제자 정보 가져오기
   */
  fetchPaymentMonthMngList = async (number) => {
    const { userEmail, userName, fromDate, endDate, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `payment/month?page=${number}&size=${postsPerPage}&from=${convertMonthFormat(
          fromDate
        )}&to=${convertMonthFormat(
          endDate
        )}&mail=${userEmail}&name=${userName}`
      );
      this.setState({ ...this.state, paymentMonthMngList: items, total });
    } catch (err) {
      console.log(err);
      //history.push("/error");
    }
  };

  /**
   * 사용자이메일, 사용자명 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * 라디오버튼 선택 시 조회기간 변경하기
   * @param {*} e
   */
  handleMonthTerm = (e) => {
    const d = new Date();
    if (e.target.value === `3`) {
      d.setMonth(d.getMonth() - 3);
      this.setState({ ...this.state, fromDate: d });
    } else if (e.target.value === `6`) {
      d.setMonth(d.getMonth() - 6);
      this.setState({ ...this.state, fromDate: d });
    }
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ ...this.state, fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ ...this.state, endDate: date });
  };
}

export default PaymentMonthMngListContainer;
