import React, { Fragment } from "react";
import PointHistoryContainer from "./fragments/PointHistory/PointHistoryContainer";

const EditUserInfo = ({
  id,
  userInfo,
  onChangeEmailAuth,
  onEditUserInfo,
  onDeleteUser,
  onClickOpenAddCash,
}) => {
  return (
    <div>
      <div className="card card-border-top mt-30 pd-0 ">
        <div className="card__header d-flex justify-content-space-between align-items-center">
          <div>
            <span>
              <i className="fas fa-user-circle fa-lg mr-5" />
              사용자 정보
            </span>
          </div>
          <div className={`d-flex`}>
          {userInfo.deleteYn !== "Y" && (
              <Fragment>
                <button className={`btn btn-s`} onClick={onEditUserInfo}>
                  <em className="fa fa-check mr-5"></em>
                  <strong>정보 수정</strong>
                </button>
                <button className={`btn btn-s bg-danger`} onClick={onDeleteUser}>
                  <em className="fa fa-times mr-5"></em>
                  <strong>회원 삭제</strong>
                </button>
              </Fragment>
            )}
          </div>
        </div>
        <div className="card__main justify-content-center">
        <div className="d-flex justify-content-center mb-5">
            <div className="form-group flex-direction-row align-items-center">
              <label>회원구분</label>
              <strong>
                {userInfo.deleteYn === "Y"
                  ? "탈퇴회원"
                  :userInfo.member_type == 2 && userInfo.adminYn === "Y" 
                  ? "기업관리자"
                  : userInfo.member_type == 2
                  ? "기업회원"
                  : userInfo.member_type == 1
                  ? "일반회원"
                  : ""}
              </strong>
            </div>
            <div className="form-group flex-direction-row align-items-center">
            {userInfo.member_type === 2 ? (
              <label>관리자승인</label>
            ):(
              <label>-</label>
            )}
              <select
                className="select-box"
                id="adminYn"
                name="adminYn"
                value={userInfo.adminYn}
                onChange={onChangeEmailAuth}
              >
               {userInfo.member_type === 1 ? (
                  // membertype이 1일 때는 미승인 옵션만 제공
                  <option value="N">-----</option>
                ) : (
                  // membertype이 2일 때는 승인, 미승인 옵션 제공
                  <React.Fragment>
                    <option value="Y">승인</option>
                    <option value="N">미승인</option>
                  </React.Fragment>
                )}
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <div className="form-group flex-direction-row align-items-center">
              <label>이름</label>
              <strong>{userInfo.name}</strong>
            </div>
            <div className="form-group flex-direction-row align-items-center">
              <label>이메일</label>
              <strong>{userInfo.email}</strong>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <div className="form-group flex-direction-row align-items-center">
              <label>휴대전화</label>
              <strong>{userInfo.hphone}</strong>
            </div>
            <div className="form-group flex-direction-row align-items-center">
              <label>전화번호</label>
              <strong>{userInfo.phoneNo}</strong>
            </div>
          </div>
          <div className="d-flex justify-content-center mb-5">
            <div className="form-group flex-direction-row align-items-center">
              <label>이메일인증</label>
              <select
                className="select-box"
                id="emailAuth"
                name="emailAuth"
                value={userInfo.emailAuth}
                onChange={onChangeEmailAuth}
              >
                <option value="Y">인증</option>
                <option value="N">미인증</option>
              </select>
            </div>
            <div className="form-group flex-direction-row align-items-center">
              <label>회사명</label>
              <strong>{userInfo.company}</strong>
            </div>
          </div>
          <div className="form-group flex-direction-row align-items-center mt-0">
            <label></label>
            <div className="d-flex align-items-center">
              <em className="fas fa-info-circle fa-sm mr-5 text-kk font-size-12"></em>
              <strong className="text-kk font-size-12">
                인증처리시 게스트 권한에서 회원권한으로 변경됩니다.
              </strong>
            </div>
          </div>
          <div className="form-group flex-direction-row align-items-center">
            <label>약관동의</label>{" "}
            <div className="c-checkbox d-flex flex-direction-column justify-content-center mb-0 line-height-two">
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="check"
                  name="agree14over"
                  checked={userInfo.agree14over === "Y"}
                  disabled
                />
                <span className="fa fa-check" />
                <strong>만 14세 이상입니다(필수)</strong>
              </label>
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="check"
                  name="agreeServiceUse"
                  checked={userInfo.agreeServiceUse === "Y"}
                  disabled
                />
                <span className="fa fa-check" />
                <strong>서비스 이용약관 동의(필수)</strong>
              </label>
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="check"
                  name="agreePersonalInfo"
                  checked={userInfo.agreePersonalInfo === "Y"}
                  disabled
                />
                <span className="fa fa-check" />
                <strong>개인정보 수집 • 이용에 대한 동의(필수)</strong>
              </label>
              <label className="d-flex align-items-center">
                <input
                  type="checkbox"
                  className="check"
                  name="agreeAd"
                  checked={userInfo.agreeAd === "Y"}
                  disabled
                />
                <span className="fa fa-check" />
                <strong>
                  전자적 전송매체를 이용한 광고성정보 수신 동의(선택)
                </strong>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-border-top mt-30 mb-30 pd-0 ">
        <div className="card__header d-flex justify-content-space-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              src="/images/kcash_ico.png"
              alt="cash-image"
              style={{ width: 20 }}
              className="mr-5"
            />
            <span>캐시 이용 내역</span>
          </div>
          <div>
            {userInfo.deleteYn !== "Y" && (
              <button className={`btn btn-s`} onClick={onClickOpenAddCash}>
                <strong>캐시 관리</strong>
              </button>
            )}
          </div>

        </div>
        <div className="card__main justify-content-center pd-20">
          <PointHistoryContainer id={id} />
        </div>
      </div>
    </div>
  );
};

export default EditUserInfo;
