import React from "react";
import "./PlanModal.scss";

const PlanModal = ({
  id,
  isOpen,
  title,
  className,
  style,
  onClickCloseModal,
  children,
}) => {
  return (
    <div
      key={id}
      id={id}
      className={"plan-modal"}
      style={{ display: isOpen ? "flex" : "none" }}
    >
      <section className="common-modal-contents" style={{ ...style }}>
        <div className={`common-modal-contents__header ${className}`}>
          <span>{title}</span>
          <div
            className="common-modal-contents__close-btn"
            onClick={onClickCloseModal}
          >
            <i className="fas fa-times" />
          </div>
        </div>
        <div className="common-modal-contents__main" style={{overflowY: "scroll"}}>{children}</div>
      </section>
    </div>
  );
};

export default PlanModal;
