import { convertDateFormat, httpRequest } from "@/util/common";
import React, { Component } from "react";
import PointHistory from "./PointHistory";

class PointHistoryContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.state = {
      pointType: "",
      fromDate: d,
      endDate: new Date(),
      histories: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchPointHistory();
  }

  render() {
    const { pointType, fromDate, endDate, histories, postsPerPage, total } =
      this.state;
    return (
      <PointHistory
        pointType={pointType}
        fromDate={fromDate}
        endDate={endDate}
        onChangeFieldValue={this.handleChangeFieldValue}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        currentPosts={histories}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchPointHistory}
      />
    );
  }

  /**
   * 캐시이용내역 정보 가져오기
   */
  fetchPointHistory = async (number = 1) => {
    const { pointType, fromDate, endDate, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `point/${
          this.props.id
        }?page=${number}&size=${postsPerPage}&from=${convertDateFormat(
          fromDate
        )}&to=${convertDateFormat(endDate)}&pointType=${pointType}`
      );

      this.setState({ ...this.state, histories: items, total });
    } catch (err) {
      history.push("/error");
    }
  };

  /**
   * 포인트타입 셀렉트박스 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ ...this.state, fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ ...this.state, endDate: date });
  };
}

export default PointHistoryContainer;
