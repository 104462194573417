import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import UserMngList from "./UserMngList";

class UserMngListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      userName: "",
      memberType:"",
      adminYn:"",
      userMngList: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchUserMngList();
  }

  render() {
    const { userEmail, userName, memberType, adminYn , userMngList, postsPerPage, total } =
      this.state;
    return (
      <UserMngList
        userEmail={userEmail}
        userName={userName}
        memberType={memberType}
        adminYn={adminYn}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={userMngList}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchUserMngList}
      />
    );
  }

  /**
   * 사용자관리 정보 가져오기
   */
  fetchUserMngList = async (number = 1) => {
    const { userEmail, userName, postsPerPage , memberType, adminYn} = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `user?page=${number}&size=${postsPerPage}&mail=${userEmail}&name=${userName}&memberType=${memberType}&adminYn=${adminYn}`
      );
      this.setState({ ...this.state, userMngList: items, total });
    } catch (err) {
      //history.push("/error");
      console.log(err);
    }
  };

  /**
   * 사용자이메일, 사용자명 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default UserMngListContainer;
