import React from "react";
import { Link } from "react-router-dom";
import { handleResetPaginationActive, commafy, httpRequest } from "@/util/common";
import ReactPaginate from "react-paginate";

const PlanCompanyList = ({
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
  onClickModal,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">요금제 현황</div>
      </div>
      <div className="page-contents">
        <div className="table-pagination-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>기업명</th>
                  <th>현 사용 요금제</th>
                  <th>현 사용량</th>
                  <th>총 사용 단어</th>
                  <th>캐시 사용</th>
                  <th>추가 결제(원)</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={6}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`userPlanList-tbody-${i}`}>
                      <td name="company">
                        <Link
                          to='#'
                          onClick={() => onClickModal(post, 1)}
                          className="text-primary text-bold">
                          {post.company}
                        </Link>
                      </td>
                      <td>
                        {post.name} <br />({post.start_date} ~ {post.end_date})
                      </td>
                      <td>
                        <span>
                          {commafy(post.plan_capacity - post.plan_usage)} / {commafy(post.plan_capacity)} 단어
                          <br />
                          ({
                            (() => {
                              const calculate = post.plan_usage - post.plan_capacity;
                              const capacityCalc = Math.floor(((post.plan_capacity - post.plan_usage) / (post.plan_capacity) * 100 - 100) * -1);
                              let calcuResult = "";
                              if (calculate > 0) {
                                calcuResult = 0;
                              } else if (calculate === 0 || capacityCalc === 'NaN') {
                                calcuResult = 0;
                              } else {
                                calcuResult = capacityCalc;
                              }
                              return calcuResult;
                            })()
                          }% 남음)
                        </span>
                      </td>
                      <td><span>{commafy(post.charCount)}</span></td>
                      <td><span>{commafy(post.pointAmount)}</span></td>
                      <td><span>{commafy(post.payAmount)}</span></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCompanyList;
