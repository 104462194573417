import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditBoardContents = ({
  boardIdx,
  publishYn,
  noticeYn,
  title,
  contents,
  attachFile,
  files,
  errors,
  onChangeFieldValue,
  onChangeContents,
  onChangeFiles,
  onRemoveFiles,
  onFileDownload,
  onRemoveAttachFile,
  onEditBoard,
  onDeleteBoard,
}) => {
  const fileRef = useRef();

  return (
    <div className="card card-border-top w-full mt-20 mb-20">
      <div className="editor__header">
        <span>게시글 수정</span>
      </div>
      <div className="divider"></div>
      <div className="editor__main">
        <div className="form-group flex-direction-row align-items-center mb-20">
          <label>게시판</label>
          {boardIdx === 1 ? <span>새소식</span> : ""}
        </div>
        <div className="d-flex align-items-center mb-10">
          <div className="form-group flex-direction-row align-items-center mb-0">
            <label>게시여부</label>
            <select
              className="select-box"
              id="publishYn"
              name="publishYn"
              value={publishYn}
              onChange={onChangeFieldValue}
            >
              <option value="Y">공개</option>
              <option value="N">비공개</option>
            </select>
          </div>
          <div className="form-group flex-direction-row align-items-center mb-0 mt-0">
            <label>공지여부</label>
            <select
              className="select-box"
              id="noticeYn"
              name="noticeYn"
              value={noticeYn}
              onChange={onChangeFieldValue}
            >
              <option value="Y">공지</option>
              <option value="N">일반</option>
            </select>
          </div>
        </div>
        <div className="mb-10">
          {" "}
          <div className="form-group flex-direction-row align-items-center">
            <label>제목</label>
            <input
              className={"form-control"}
              type="text"
              name="title"
              placeholder="제목을 입력해주세요."
              value={title}
              onChange={onChangeFieldValue}
            />
          </div>
          <p className="validation-message ml-90 mb-10">{errors.title}</p>
        </div>
        <div className="mb-20">
          <div className="form-group flex-direction-row align-items-center">
            <label>내용</label>
            <CKEditor
              editor={ClassicEditor}
              data={contents}
              onChange={(event, editor) => {
                const data = editor.getData();
                onChangeContents(data);
              }}
            />
          </div>
          <p className="validation-message ml-90">{errors.contents}</p>
        </div>
        <div className="attach-file">
          {" "}
          {attachFile &&
            attachFile.length > 0 &&
            attachFile.map((v, i) => (
              <div key={`edit-attach-file-${i}`} className="attach-file__item">
                <Link
                  to="#"
                  onClick={() => onFileDownload(v.file_idx, v.org_file_name)}
                >
                  {v.org_file_name}
                </Link>
                <em
                  className="fa fa-times ml-5"
                  onClick={() => onRemoveAttachFile(v)}
                ></em>
              </div>
            ))}
          {files &&
            files.length > 0 &&
            files.map((file, i) => (
              <div
                key={`edit-file-${i}`}
                className="attach-file__item border-warning"
              >
                {file.name}
                <em
                  className="fa fa-times ml-5"
                  onClick={() => onRemoveFiles(file)}
                ></em>
              </div>
            ))}
        </div>

        <div className="form-group flex-direction-row align-items-center">
          <label>첨부파일</label>
          <input
            ref={fileRef}
            className={"form-control w-one-three"}
            type="file"
            name="file"
            onChange={(e) => {
              onChangeFiles(e);
              fileRef.current.value = "";
            }}
          />
        </div>
      </div>

      <div className="divider"></div>
      <div className="editor__footer">
        <div>
          <Link to="/board/boardContents">
            <button className="list-btn">
              <em className="fas fa-list-ul mr-5"></em>
              <strong>목록</strong>
            </button>
          </Link>
        </div>

        <div className={`d-flex`}>
          <button className={`btn btn-s`} onClick={onEditBoard}>
            <em className="fa fa-check mr-5"></em>
            <strong>게시글 수정</strong>
          </button>
          <button className={`btn btn-s bg-danger`} onClick={onDeleteBoard}>
            <em className="fa fa-times mr-5"></em>
            <strong>게시글 삭제</strong>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditBoardContents;
