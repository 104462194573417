import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import UsageMngList from "./UsageMngList";

class UsageMngListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: "",
      userName: "",
      usageMngList: [],
      postsPerPage: 6,
      total: 0,
      usageInfo: [],
    };
  }

  componentDidMount() {
    this.fetchUsageMngList(1);
  }

  render() {
    const { userEmail, userName, usageMngList, postsPerPage, total, usageInfo } = this.state;
    return (
      <UsageMngList
        userEmail={userEmail}
        userName={userName}
        currentUsage={usageInfo}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={usageMngList}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchUsageMngList}
      />
    );
  }

  /**
   * 청구 정보 가져오기
   */
  fetchUsageMngList = async (number) => {
    const { userEmail, userName, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total, usages },
      } = await httpRequest.get(
        `usage?page=${number}&size=${postsPerPage}&mail=${userEmail}&name=${userName}`
      );
      this.setState({ ...this.state, usageMngList: items, total, usageInfo: usages });
    } catch (err) {
      console.log(err);
      //history.push("/error");
    }
  };

  /**
   * 사용자이메일, 사용자명, 요금제 인풋, select 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default UsageMngListContainer;
