import React, { Component } from "react";
import PlanCompanyDetail from "./PlanCompanyDetail";
import { calculateCash, httpRequest, TRANS_CODE_DATA } from "@/util/common";
import { connect } from "react-redux";

class PlanCompanyDetailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: {},
      sendCompany: ""
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.chosen !== prevProps.chosen) {
      const chosen = { ...this.props.chosen };
      const sendCompany = { ...this.props.sendCompany };
      this.setState({
        chosen,
        sendCompany
      });
    }
  }

  render() {
    const { onClickCloseModal } = this.props;
    const { chosen, sendCompany } = this.state;
    
    return (
      <PlanCompanyDetail
        chosen={chosen}
        sendCompany={sendCompany}
        onClickCloseModal={onClickCloseModal}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(PlanCompanyDetailContainer);