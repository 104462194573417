import React from "react";
import { Link } from "react-router-dom";
import { handleResetPaginationActive, commafy } from "@/util/common";
import ReactPaginate from "react-paginate";
import "./UsageMngList.scss";

const UsageMngList = ({
  userEmail,
  userName,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
  currentUsage,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">[번역 가능 단어수] 관리</div>
      </div>
      <div className="page-contents">
        <div className="d-flex mb-20">
          <input
            className={"form-control w-one-five mr-10"}
            type="text"
            name="userEmail"
            placeholder="사용자 이메일"
            value={userEmail}
            onChange={onChangeFieldValue}
          />
          <input
            className={"form-control w-one-three mr-10"}
            type="text"
            name="userName"
            placeholder="사용자 명"
            value={userName}
            onChange={onChangeFieldValue}
          />
          <button
            className="btn btn-s"
            onClick={() => {
              onFetchPage(1);
              handleResetPaginationActive();
            }}
          >
            조회
          </button>
        </div>
        <div className="table-pagination-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>회원ID</th>
                  <th>요금제 플랜</th>
                  <th>번역 가능 단어수</th>
                  <th>사용 단어수</th>
                  <th>잔여 단어수</th>
                </tr>
              </thead>
              <tbody>
                {currentUsage && currentUsage.length === 0 && (
                  <tr>
                    <td colSpan={6}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentUsage &&
                  currentUsage.length > 0 &&
                  currentUsage.map((post, i) => (
                    <tr key={`planList-tbody-${i}`}>
                      <td><span>{post.email}</span></td>
                      <td><span>{post.description}</span></td>
                      <td><span>{commafy(post.max_char_count)}</span></td>
                      <td><span>{commafy(post.char_count)}</span></td>
                      <td><span>{commafy(post.charValue)}</span></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>  
        <div className="table-pagination-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>요청일자</th>
                  <th>이용자ID</th>
                  <th>종류</th>
                  <th>번역 대상 단어수</th>
                  <th>요금제 사용 단어수</th>
                  <th>보유캐시 사용</th>
                  <th>단건결제 사용</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={8}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`usageHistList-tbody-${i}`}>
                      <td><span>{post.reg_dttm}</span></td>
                      <td>
                        <Link
                          to={`/user/userMng/${post.user_idx}`}
                          className="text-primary text-bold">
                          {post.email}
                        </Link>
                      </td>
                      <td><span>{post.memo}</span></td>
                      <td><span>{commafy(post.charCount)}</span></td>
                      <td><span>{commafy(post.planAmount)}</span></td>
                      <td><span>{commafy(post.pointAmount)}</span></td>
                      <td><span>{commafy(post.payAmount)}</span></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageMngList;
