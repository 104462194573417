import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import EditInquiryContents from "./EditInquiryContents";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class EditInquiryContentsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boardIdx: 3,
      comment: "",
      boardContentsIdx: "",
      commentIdx: "",
    };
  }

  componentDidMount() {
    this.fetchInquiryComments();
  }

  render() {
    const {
      boardIdx,
      comment,
      commentIdx,
      boardContentsIdx,
    } = this.state;
    return (
      <EditInquiryContents
        boardIdx={boardIdx}
        boardContentsIdx={boardContentsIdx}
        commentIdx={commentIdx}
        comment={comment}
        onChangeFieldValue={this.handleChangeFieldValue}
        onModComment={this.handleEditComment}
      />
    );
  }

  /**
   * BoardContent 받아오기
   */
  fetchInquiryComments = async () => {
    const {
      params: { id },
    } = this.props.match;
    try {
      const {
        data: { comment },
      } = await httpRequest.get(`board/comment/${id}`);

      this.setState({
        ...this.state,
        boardIdx: comment[0].board_idx,
        boardContentsIdx: comment[0].board_contents_idx,
        comment: comment[0].comment,
        commentIdx: comment[0].comment_idx,
      });
    } catch (err) {
      this.props.history("/error");
    }
  };

  /**
   * 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * 코멘트 수정 요청하기
   */
  handleEditComment = async (boardIdx, boardContentsIdx, commentIdx) => {
    const { comment } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
      try {
        const formData = new FormData();
        formData.append("boardIdx", boardIdx);
        formData.append("boardContentsIdx", boardContentsIdx);
        formData.append("commentIdx", commentIdx);
        formData.append("comment", comment);

        await httpRequest.put(`board/comment/${commentIdx}`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        alert("정상적으로 수정되었습니다.");
        this.props.history.push("/board/inquiry/" + boardContentsIdx);
      } catch (err) {
        alert("코멘트 수정에 실패하였습니다.");
      }
  };
}

export default EditInquiryContentsContainer;
