import React from "react";
import { Link } from "react-router-dom";
import { handleResetPaginationActive, commafy } from "@/util/common";
import ReactPaginate from "react-paginate";
import "./PlanMngList.scss";

const PlanMngList = ({
  userEmail,
  userName,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
  planType,
  currentPlan,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">요금제 플랜 구독 현황</div>
      </div>
      <div className="page-contents">
        <div className="d-flex mb-20">
          <input
            className={"form-control w-one-five mr-10"}
            type="text"
            name="userEmail"
            placeholder="사용자 이메일"
            value={userEmail}
            onChange={onChangeFieldValue}
          />
          <input
            className={"form-control w-one-three mr-10"}
            type="text"
            name="userName"
            placeholder="사용자 명"
            value={userName}
            onChange={onChangeFieldValue}
          />
          <select
            className="select-box mr-20"
            id="planType"
            name="planType"
            value={planType}
            onChange={onChangeFieldValue}
          >
            <option value="">전체</option>
            <option value={1}>Basic</option>
            <option value={2}>Standard</option>
            <option value={3}>Pro</option>
            <option value={4}>Enterprise</option>
          </select>
          <button
            className="btn btn-s"
            onClick={() => {
              onFetchPage(1);
              handleResetPaginationActive();
            }}
          >
            조회
          </button>
        </div>
        <div className="table-pagination-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>전체플랜</th>
                  <th>Basic 요금제</th>
                  <th>Standard 요금제</th>
                  <th>Pro 요금제</th>
                </tr>
              </thead>
              <tbody>
                {currentPlan && currentPlan.length === 0 && (
                  <tr>
                    <td colSpan={5}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPlan &&
                  currentPlan.length > 0 &&
                  currentPlan.map((post, i) => (
                    <tr key={`planList-tbody-${i}`}>
                      <td><span>{commafy(post.total_capacity)}</span></td>
                      <td><span>{commafy(post.basic_capacity)}</span></td>
                      <td><span>{commafy(post.standard_capacity)}</span></td>
                      <td><span>{commafy(post.pro_capacity)}</span></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>  
        <div className="table-pagination-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>요금제 플랜</th>
                  <th>회원ID</th>
                  <th>구독기간</th>
                  <th>번역 가능 단어수</th>
                  <th>사용 단어수</th>
                  <th>잔여 단어수</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={7}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`userPlanList-tbody-${i}`}>
                      <td><span>{post.description}</span></td>
                      <td>
                        <Link
                          to={`/user/userMng/${post.user_idx}`}
                          className="text-primary text-bold">
                          {post.email}
                        </Link>
                      </td>
                      <td><span>{post.start_date} ~ {post.end_date}</span></td>
                      <td><span>{commafy(post.maxCharCount)}</span></td>
                      <td><span>{commafy(post.charCount)}</span></td>
                      <td><span>{commafy(post.charValue)}</span></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanMngList;
