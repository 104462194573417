import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import InquiryDtl from "./InquiryDtl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class InquiryDtlContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      comment: {},
      attachFile: [],
      commentVal: "",
    };
  }

  componentDidMount() {
    this.fetchInquiryDtl();
  }
  render() {
    const { content, comment, attachFile, commentVal } = this.state;
    return (
      <InquiryDtl
        post={content}
        postComment={comment}
        attachFile={attachFile}
        commentVal={commentVal}
        regComment={this.handleRegComment}
        modComment={this.handleModComment}
        delComment={this.handleDelComment}
        onChangeFieldValue={this.handleChangeFieldValue}
        onFileDownload={this.handleFileDownload}
        onDeleteComment={this.handleDeleteComment}
      />
    );
  }

  /**
   * 서버에서 새소식 상세정보 받아오기
   */
  fetchInquiryDtl = async () => {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    try {
      const {
        data: { content, comment, attachFile },
      } = await httpRequest.get(`board/inquiry/${id}`);
      this.setState({ content, comment, attachFile });
    } catch (err) {
      history.push(`/error?code=${err.response.status}`);
    } finally {
      this.setState({ ...this.state });
    }
  };

  handleChangeFieldValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * 첨부파일 다운로드 받기
   * @param {*} fileIdx
   * @returns
   */
  handleFileDownload = async (fileIdx, orgFileName) => {
    const url = `${ip_config.baseUrl}/file/${fileIdx}`;
    try {
      const { data } = await httpRequest.get(url, { responseType: "blob" });
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", orgFileName);
      document.body.append(link);
      link.click();
    } catch (err) {
      toast.error("다운로드 실패하였습니다.");
    }
  };

  /**
   * 코멘트트 삭제 요청하기
   */
  handleDeleteComment = async (boardContentsIdx, commentIdx) => {
    const {
      params: { id },
    } = this.props.match;
    try {
      if (confirm("삭제하시겠습니까?")) {
        await httpRequest.delete(`board/comment/${boardContentsIdx}/${commentIdx}`, { data: {} });
        alert("정상적으로 삭제되었습니다.");
        location.reload();
      }
    } catch (err) {
      alert("삭제 실패하였습니다.");
    }
  };

  /**
   * 코멘트 등록
   */
  handleRegComment = async() => {
    const { commentVal } = this.state;
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    try {
      const formData = new FormData();
      formData.append("comment", commentVal);
      formData.append("boardIdx", 3);
      formData.append("boardContentsIdx", id);

      await httpRequest.post(`board/insertComment`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      alert("정상적으로 등록되었습니다.");
      //this.props.history.push(`/board/inquiry`);
      location.reload();
    } catch (err) {
      alert("게시물 등록에 실패하였습니다.");
    } finally {
      this.setState({ ...this.state });
    }
  };

  handleDelComment = async () => {

  };
}

export default InquiryDtlContainer;
