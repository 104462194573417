import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import EditPaypalContents from "./EditPaypalContents";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class EditPaypalContentsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      planInfo:{
        joinYn:"0",
        charCount:"",
        price:"'",
        name:"",
        description:"",
        planId:"0",
        currency:"",
        userIdx: "",
        paypalTransactionId:"0",
        productName:"",
        paypalRequestId:"",
        productId:"",
        planName:"",
        intervalUnit:"MONTH",
        totalCycles:"",
        value:"",
        setupFee:"0",
        paypalResult:"",
        hasSubscription:"",
      },
      errors: {
        title: "",
        contents: "",
      },
    };
  }

  componentDidMount() {
    const {id} = this.props.match.params;
    if(id !== "0" ){
      this.fetchPlanContent();
    }
  }

  render() {
    const {
      planInfo,
      requestInfo,
      errors,
    } = this.state;
    return (
      <EditPaypalContents
        requestInfo={requestInfo}
        planInfo={planInfo}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        fetchPlanContent={this.fetchPlanContent}
        handleEditPlan={this.handleEditPlan}
      />
    );
  }

  /**
   * Plan정보 받아오기
   */
  fetchPlanContent = async () => {
    const {
      params: { id },
    } = this.props.match;
    try {
      const {
        data: { contents },
      } = await httpRequest.get(`plan/paypal/detail?id=${id}`);
      console.log(contents);
      this.setState((prevState) => ({
        planInfo: Object.assign({}, prevState.planInfo, contents),
      }));
    } catch (err) {
      console.log(err)
      window.location.href = "/";
    }

    console.log("요기"+JSON.stringify(this.state))
  };
  /**
   * 플랜 및 구독정보 insert or Update
   */
  handleEditPlan = async () => {
    console.log("check state " +JSON.stringify(this.state.planinfo))
    const {
      params: { id },
    } = this.props.match;
    const {planInfo} = this.state;
    if(planInfo.hasSubscription === "Y"){
      return false;
    }
    try{
      const response = await httpRequest.post(`plan/paypal/createSubscription`,
        {planInfo:planInfo});

      console.log("결과는?" + JSON.stringify(response));
      if(response.data === "success"){
        alert("등록되었습니다.");
        window.location.href = "/plan/paypalPlanMngList";
      }else if(response.data === "error"){
        alert("에러가 발생하였습니다.관리자에게 문의하세요")
        window.location.href = "/plan/paypalPlanMngList";
      }else{
        alert("에러가 발생하였습니다.관리자에게 문의하세요")
        window.location.href = "/plan/paypalPlanMngList";
      }
    }catch(err){
      console.log(err);
    }

  }

  /**
   * 게시여부,공지여부,제목 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    const { name, value } = e.target; 
    this.setState((prevState) => ({
      ...prevState, 
      planInfo: {
        ...prevState.planInfo, 
        [name]: value, 
      },
    }));
  };

  /**
   * ckEditor 내용 변경하기
   * @param {*} data
   */
  handleChangeContents = (data) => {
    this.setState({ ...this.state, contents: data });
  };

  /**
   * 첨부파일 핸들러
   * @param {*} e
   */
  handleChangeFiles = (e) => {
    const files = [...this.state.files, e.target.files[0]];
    this.setState({ ...this.state, files });
  };

  /**
   * 첨부파일 뱃지 x버튼 핸들러
   * @param {*} file
   */
  handleRemoveFiles = (file) => {
    const files = this.state.files.filter(
      (v) => v.lastModified !== file.lastModified
    );
    this.setState({ ...this.state, files });
  };

  /**
   * 첨부파일 다운로드 받기
   * @param {*} fileIdx
   * @returns
   */
  handleFileDownload = async (fileIdx, orgFileName) => {
    const url = `${ip_config.clientUrl}/board/file/${fileIdx}`;
    try {
      const { data } = await httpRequest.get(url, { responseType: "blob" });
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.download = orgFileName;
      document.body.append(link);
      link.click();
    } catch (err) {
      toast.error("다운로드 실패하였습니다.");
    }
  };

  /**
   * 기존 첨부파일 삭제하기
   * @param {*} file
   */
  handleRemoveAttachFile = (file) => {
    const attachFile = this.state.attachFile.filter(
      (v) => v.file_idx !== file.file_idx
    );
    const detachFileIdx = [...this.state.detachFileIdx, file.file_idx];
    this.setState({ ...this.state, attachFile, detachFileIdx });
  };

  /**
   * 유효성검사하기
   * @returns
   */
  validate = () => {
    let validated = true;
    const errors = {};
    const { planinfo } = this.state;
    if(planInfo.planName){

    }
    if(planInfo.planName){
      
    }
    if(planInfo.planName){
      
    }

    if (!title) {
      errors.title = "제목을 입력해주세요";
      validated = false;
    }
    if (!contents) {
      errors.contents = "내용을 입력해주세요";
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 게시글 수정 요청하기
   */
  handleEditBoard = async () => {
    const { publishYn, noticeYn, title, contents, files, detachFileIdx } =
      this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (contents.length > 20000) {
      alert("20,000자 이하로 입력해주세요.");
      return;
    }
    if (this.validate()) {
      try {
        const formData = new FormData();

        formData.append("publishYn", publishYn);
        formData.append("noticeYn", noticeYn);
        formData.append("title", title);
        formData.append("contents", contents);
        files.forEach((file) => {
          formData.append("files", file);
        });
        detachFileIdx.forEach((v) => {
          formData.append("removeFiles", v);
        });

        await httpRequest.put(`board/news/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        alert("정상적으로 수정되었습니다.");
        this.props.history.push("/board/boardContents");
      } catch (err) {
        alert("게시물 수정에 실패하였습니다.");
      }
    }
  };

  /**
   * 게시글 삭제 요청하기
   */
  handleDeleteBoard = async () => {
    const {
      params: { id },
    } = this.props.match;
    try {
      if (confirm("삭제하시겠습니까?")) {
        await httpRequest.delete(`board/news/${id}`, { data: {} });
        alert("정상적으로 삭제되었습니다.");
        this.props.history.push("/board/boardContents");
      }
    } catch (err) {
      alert("삭제 실패하였습니다.");
    }
  };
}

export default EditPaypalContentsContainer;
