import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "./Navbar";
import { logout } from "@/commonSlices/authSlice";

class NavbarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerClicked: false,
      showDropdown: false,
      showPlanDropdown: false,
    };
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { hamburgerClicked } = this.state;
    return (
      <Navbar
        hamburgerClicked={hamburgerClicked}
        onHamburgerClick={this.handleHamburgerClick}
        showDropdown={this.state.showDropdown}
        showPlanDropdown={this.state.showPlanDropdown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseOn={this.onMouseOn}
        onMouseOff={this.onMouseOff}
        isAuthenticated={isAuthenticated}
        logout={this.props.logout}
      />
    );
  }

  /**
   * 반응형 헤더 햄버거버튼 열고닫기
   */
  handleHamburgerClick = () => {
    this.setState({ hamburgerClicked: !this.state.hamburgerClicked });
  };

  /**
   * 드롭다운 열고닫기
   */
  onMouseEnter = () => {
    this.setState({ showDropdown: true });
  };

  onMouseLeave = () => {
    this.setState({ showDropdown: false });
  };
  onMouseOn = () => {
    this.setState({ showPlanDropdown: true });
  };

  onMouseOff = () => {
    this.setState({ showPlanDropdown: false });
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { logout })(NavbarContainer);
