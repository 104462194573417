import React from "react";
import "./PointHistory.scss";
import Calendar from "@/commonModule/Calendar/Calendar";
import { commafy, handleResetPaginationActive } from "@/util/common";
import ReactPaginate from "react-paginate";

const PointHistory = ({
  pointType,
  fromDate,
  endDate,
  onChangeFieldValue,
  setFromDate,
  setEndDate,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div>
      <div className="d-flex justify-content-space-evenly align-items-center w-two-three">
        <select
          className="select-box"
          id="pointType"
          name="pointType"
          value={pointType}
          onChange={onChangeFieldValue}
        >
          <option value="">전체</option>
          <option value="0">충전</option>
          <option value="1">사용</option>
        </select>
        <Calendar
          fromDate={fromDate}
          setFromDate={setFromDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <button
          className="btn btn-s"
          onClick={() => {
            onFetchPage(1);
            handleResetPaginationActive();
          }}
        >
          조회
        </button>
      </div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="table-container point-history__table-container">
          {" "}
          <table>
            <thead>
              <tr>
                <th>일시</th>
                <th>종류</th>
                <th>캐시</th>
                <th>잔액</th>
                <th>주문번호</th>
                <th>메모</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts && currentPosts.length === 0 && (
                <tr>
                  <td colSpan={6}>검색결과가 없습니다.</td>
                </tr>
              )}
              {currentPosts &&
                currentPosts.length > 0 &&
                currentPosts.map((post, i) => (
                  <tr key={`tbody-${i}`}>
                    <td className="text-bold">{post.reg_dttm}</td>
                    <td
                      className={`text-bold ${
                        post.type_name === "사용"
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {post.type_name}
                    </td>
                    <td
                      className={`text-bold ${
                        post.type_name === "사용"
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {commafy(post.amount)}캐시
                    </td>
                    <td className="text-bold">{commafy(post.balance)}캐시</td>
                    <td className="text-bold">{post.order_no}</td>
                    <td className="text-bold">{post.memo}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

export default PointHistory;
