import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import CreateFaqContents from "./CreateFaqContents";

class CreateFaqContentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqType: "",
      publishYn: "Y",
      title: "",
      contents: "",
      errors: {
        faqType: "",
        title: "",
        contents: "",
      },
    };
  }
  render() {
    const { faqType, publishYn, title, contents, errors } = this.state;
    return (
      <CreateFaqContents
        faqType={faqType}
        publishYn={publishYn}
        title={title}
        contents={contents}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onChangeContents={this.handleChangeContents}
        onCreateFaq={this.handleCreateFaq}
      />
    );
  }

  /**
   * 유효성검사하기
   * @returns
   */
  validate = () => {
    let validated = true;
    const errors = {};
    const { faqType, title, contents } = this.state;
    if (!faqType) {
      errors.faqType = "질문유형을 선택해주세요";
      validated = false;
    }
    if (!title) {
      errors.title = "질문을 입력해주세요";
      validated = false;
    }
    if (!contents) {
      errors.contents = "답변을 입력해주세요";
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * faqType,게시여부,title 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * ckEditor 내용 변경하기
   * @param {*} data
   */
  handleChangeContents = (data) => {
    this.setState({ ...this.state, contents: data });
  };

  /**
   * FAQ 등록 요청하기
   */
  handleCreateFaq = async () => {
    const { faqType, publishYn, title, contents } = this.state;
    if (contents.length > 20000) {
      alert("20,000자 이하로 입력해주세요.");
      return;
    }
    if (this.validate()) {
      try {
        const formData = new FormData();
        formData.append("faqType", faqType);
        formData.append("publishYn", publishYn);
        formData.append("title", title);
        formData.append("contents", contents);

        await httpRequest.post(`board/faq`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        alert("정상적으로 등록되었습니다.");
        this.props.history.push("/board/faqContents");
      } catch (err) {
        alert("게시물 등록에 실패하였습니다.");
      }
    }
  };
}

export default CreateFaqContentsContainer;
