import { convertDateFormat, httpRequest, MONTH_TERM_INFO } from "@/util/common";
import React, { Component } from "react";
import ChargeMngList from "./ChargeMngList";

class ChargeMngListContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 3);
    this.state = {
      planType: "",
      userEmail: "",
      userName: "",
      chargeMngList: [],
      fromDate: d,
      endDate: new Date(),
      postsPerPage: 6,
      total: 0,
    };
    this.monthTermList = Object.keys(MONTH_TERM_INFO).map((key) => {
      const obj = MONTH_TERM_INFO[key];
      return {
        label: obj.label,
        value: obj.monthTerm,
        defaultChecked: key === `THREE_MONTH`,
      };
    });
  }

  componentDidMount() {
    this.fetchChargeMngList(1);
  }

  render() {
    const { planType, userEmail, userName, chargeMngList, postsPerPage, total, fromDate, endDate } = this.state;
    return (
      <ChargeMngList
        planType={planType}
        monthTermList={this.monthTermList}
        onChangeMonthTerm={this.handleMonthTerm}
        fromDate={fromDate}
        endDate={endDate}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        userEmail={userEmail}
        userName={userName}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={chargeMngList}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchChargeMngList}
      />
    );
  }

  /**
   * 청구 정보 가져오기
   */
  fetchChargeMngList = async (number) => {
    const { planType, userEmail, userName, fromDate, endDate, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `charge?page=${number}&size=${postsPerPage}&from=${convertDateFormat(
          fromDate
        )}&to=${convertDateFormat(
          endDate
        )}&mail=${userEmail}&name=${userName}&planType=${planType}`
      );
      this.setState({ ...this.state, chargeMngList: items, total });
    } catch (err) {
      console.log(err);
      //history.push("/error");
    }
  };

  /**
   * 사용자이메일, 사용자명 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * 라디오버튼 선택 시 조회기간 변경하기
   * @param {*} e
   */
  handleMonthTerm = (e) => {
    const d = new Date();
    if (e.target.value === `3`) {
      d.setMonth(d.getMonth() - 3);
      this.setState({ ...this.state, fromDate: d });
    } else if (e.target.value === `6`) {
      d.setMonth(d.getMonth() - 6);
      this.setState({ ...this.state, fromDate: d });
    }
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ ...this.state, fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ ...this.state, endDate: date });
  };
}

export default ChargeMngListContainer;
