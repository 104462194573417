import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import FaqContentsList from "./FaqContentsList";

class FaqContentsListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqType: "",
      searchTitle: "",
      faqContents: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchFaqContents();
  }

  render() {
    const { faqType, searchTitle, faqContents, postsPerPage, total } =
      this.state;

    return (
      <FaqContentsList
        faqType={faqType}
        searchTitle={searchTitle}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={faqContents}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchFaqContents}
      />
    );
  }

  /**
   * FaqContents 리스트 받아오기
   */
  fetchFaqContents = async (number = 1) => {
    const { faqType, searchTitle, postsPerPage } = this.state;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `board/faq?page=${number}&size=${postsPerPage}&title=${searchTitle}&faqType=${faqType}`
      );
      this.setState({ ...this.state, faqContents: items, total });
    } catch (err) {
      this.props.history.push("/error");
    }
  };

  /**
   * faqType, 검색제목 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default FaqContentsListContainer;
