import React from "react";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditFaqContents = ({
  faqType,
  publishYn,
  title,
  contents,
  errors,
  onChangeFieldValue,
  onChangeContents,
  onEditFaq,
  onDeleteFaq,
}) => {
  return (
    <div className="card card-border-top w-full mt-20 mb-20">
      <div className="editor__header">
        <span>FAQ 수정</span>
      </div>
      <div className="divider"></div>
      <div className="editor__main">
        <div className="form-group flex-direction-row align-items-center mb-20">
          <label>질문유형</label>
          <span>{showFaqType(faqType)}</span>
        </div>

        <div className="form-group flex-direction-row align-items-center">
          <label>게시여부</label>
          <select
            className="select-box"
            id="publishYn"
            name="publishYn"
            value={publishYn}
            onChange={onChangeFieldValue}
          >
            <option value="Y">공개</option>
            <option value="N">비공개</option>
          </select>
        </div>

        <div className="form-group flex-direction-row align-items-center">
          <label>질문</label>
          <input
            className={"form-control"}
            type="text"
            name="title"
            placeholder="제목을 입력해주세요."
            value={title}
            onChange={onChangeFieldValue}
          />
        </div>
        <p className="validation-message ml-90 mb-10">{errors.title}</p>
        <div>
          <div className="form-group flex-direction-row align-items-center">
            <label>답변</label>
            <CKEditor
              editor={ClassicEditor}
              data={contents}
              onChange={(event, editor) => {
                const data = editor.getData();
                onChangeContents(data);
              }}
            />
          </div>
          <p className="validation-message ml-90 mb-10">{errors.contents}</p>
        </div>
      </div>

      <div className="divider"></div>
      <div className="editor__footer">
        <div>
          <Link to="/board/faqContents">
            <button className="list-btn">
              <em className="fas fa-list-ul mr-5"></em>
              <strong>목록</strong>
            </button>
          </Link>
        </div>

        <div className={`d-flex`}>
          <button className={`btn btn-s`} onClick={onEditFaq}>
            <em className="fa fa-check mr-5"></em>
            <strong>FAQ 수정</strong>
          </button>
          <button className={`btn btn-s bg-danger`} onClick={onDeleteFaq}>
            <em className="fa fa-times mr-5"></em>
            <strong>FAQ 삭제</strong>
          </button>
        </div>
      </div>
    </div>
  );
};

/**
 * 질문유형 표시
 * @param {*} faqType
 * @returns
 */
function showFaqType(faqType) {
  switch (faqType) {
    case "0":
      return "전체";
    // case "1":
    //   return "일반";
    // case "2":
    //   return "가입/인증/탈퇴";
    // case "3":
    //   return "로그인/계정";
    // case "4":
    //   return "서비스 이용";
    // case "5":
    //   return "결제/충전";
    case "1":
      return "회원정보";
    case "2":
      return "결제/환불";
    case "3":
      return "시스템/에러";
    case "4":
      return "사용법";
    case "5":
      return "기타";
    default:
      throw new Error(`unknown faqType...`);
  }
}

export default EditFaqContents;
