import { pickBadgeStyle } from "@/util/common";
import React from "react";
import ReactPaginate from "react-paginate";

const ProgressSheet = ({
  progressType,
  currentPosts,
  totalPosts,
  postsPerPage,
  onClickState,
}) => {
  return (
    <div className="table-pagination-container">
      {progressType && (
        <div className="table-title">
          <div>
            <span className={`badge ${pickStyles(progressType).className}`}>
              {pickStyles(progressType).text}
            </span>
          </div>
        </div>
      )}

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>요청번호</th>
              <th>요청자정보</th>
              <th>주문번호</th>
              <th>내용</th>
            </tr>
          </thead>
          <tbody>
            {!progressType && (
              <tr>
                <td colSpan={5}>상태별 건수를 클릭해주세요.</td>
              </tr>
            )}
            {progressType && currentPosts && currentPosts.length === 0 && (
              <tr>
                <td colSpan={5}>검색결과가 없습니다.</td>
              </tr>
            )}
            {progressType &&
              currentPosts &&
              currentPosts.length > 0 &&
              currentPosts.map((post, i) => (
                <tr key={`standby-tbody-${i}`}>
                  <td>
                    <span>{post.trans_req_idx}</span>
                  </td>
                  <td className="line-height-6">
                    <div>{post.req_user_name}</div>
                    <div>{post.req_user_email}</div>
                  </td>
                  <td className="line-height-6">
                    <div>{post.order_no}</div>
                    <div>
                      <small>{`(${post.req_dttm})`}</small>
                    </div>
                  </td>
                  <td className="d-flex justify-content-space-between">
                    <div className="text-align-start line-height-two ml-20">
                      <div>{post.trans_coverage_name}</div>
                      <div className="text-bold">{post.doc_no}</div>
                    </div>
                    <div className="text-align-end line-height-two mr-20">
                      <span
                        className={`badge ${pickBadgeStyle(post.trans_state)}`}
                      >
                        {post.trans_state_name}
                      </span>
                      <div>
                        <span className="text-bold mr-5">
                          {" "}
                          {post.trans_type_name}
                        </span>
                        <span>
                          {" "}
                          {post.org_lang}{" "}
                          <em className="fas fa-arrow-right"></em>{" "}
                          {post.trans_lang}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={(e) => {
            onClickState(progressType, e.selected + 1);
          }}
          pageRangeDisplayed={5}
          pageCount={Math.ceil(totalPosts / postsPerPage)}
          previousLabel="< prev"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </div>
  );
};

function pickStyles(type) {
  switch (type) {
    case "ready":
      return { text: "대기", className: "badge-warning" };
    case "ongoing":
      return { text: "진행중", className: "badge-primary" };
    case "end_today":
      return { text: "금일완료", className: "badge-success" };
    case "delay":
      return { text: "납품기한 초과", className: "badge-danger" };
    case "fail":
      return { text: "번역요청 실패", className: "badge-danger" };

    default:
      return { text: "", className: "" };
  }
}

export default ProgressSheet;
