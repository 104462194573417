import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";

const Navbar = ({
  hamburgerClicked,
  onHamburgerClick,
  showDropdown,
  onMouseEnter,
  onMouseLeave,
  onMouseOn,
  onMouseOff,
  showPlanDropdown,
  isAuthenticated,
  logout,
}) => {
  const authLink = (
    <Fragment>
      {" "}
      {/* <li>
        <Link className="nav-menu__link" to="/transProgressInfo">
          번역현황요약
        </Link>
      </li> */}
      <li>
        <Link className="nav-menu__link" to="/transReqMng">
          번역요청관리
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="/transReqFailMng">
          번역요청지연목록
        </Link>
      </li>

      {/* 20241107 시연 미사용으로 주석 처리 S*/}
      <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Link className="nav-menu__link" to="#">
          게시판관리
        </Link>
        {showDropdown && (
          <Fragment>
            <ul className="dropdown-menu" onClick={onMouseLeave}>
              <li>
                <Link to="/board/boardContents" className="dropdown-menu__link">
                  <span>일반게시판</span>
                </Link>
              </li>
              <li>
                <Link to="/board/faqContents" className="dropdown-menu__link">
                  <span>FAQ</span>
                </Link>
              </li>
              <li>
                <Link to="/board/inquiry" className="dropdown-menu__link">
                  <span>1:1문의</span>
                </Link>
              </li>
            </ul>
          </Fragment>
        )}
      </li>
      
      <li onMouseEnter={onMouseOn} onMouseLeave={onMouseOff}>
        <Link className="nav-menu__link" to="#">
          플랜관리
        </Link>
        {showPlanDropdown && (
          <Fragment>
            <ul className="dropdown-menu" onClick={onMouseOff}>
              <li>
                <Link className="dropdown-menu__link" to="/plan/paypalPlanMngList">
                  플랜관리
                </Link>
              </li>
              <li>
                <Link className="dropdown-menu__link" to="/plan/planCompanyList">
                  요금제 현황
                </Link>
              </li>
              {/* 20241107 시연 미사용으로 주석 처리 S*/}
              {/* <li>
                <Link className="dropdown-menu__link" to="/plan/planMng">
                  요금제 플랜 구독 현황
                </Link>
              </li>
              <li>
                <Link className="dropdown-menu__link" to="/usage/usageMng">
                  번역 가능 단어수 관리
                </Link>
              </li>
              <li>
                <Link className="dropdown-menu__link" to="/charge/chargeMng">
                  청구현황
                </Link>
              </li>
              */}
              {/* 20241107 시연 미사용으로 주석 처리 E*/}
              <li>
                <Link className="dropdown-menu__link" to="/payment/paymentMng">
                  결제현황
                </Link>
              </li>
              <li>
                <Link className="dropdown-menu__link" to="/payment/paymentMonthMng">
                  월별 결제현황
                </Link>
              </li>
            </ul>
          </Fragment>
        )}
      </li>
      <li>
        <Link className="nav-menu__link" to="/user/userMng">
          사용자관리
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="#" onClick={logout}>
          <div>
            <i className="fas fa-sign-out-alt" />
            <span>로그아웃</span>{" "}
          </div>
        </Link>
      </li>
    </Fragment>
  );

  return (
    <div>
      <nav className="navbar">
        <div className="navbar__logo">
          <Link to="/">
            <img src="/images/logo.png" alt="logo" />
          </Link>
        </div>
        <div className="navbar__hamburger-btn" onClick={onHamburgerClick}>
          <i className={hamburgerClicked ? `fas fa-times` : `fas fa-bars`}></i>
        </div>
        <ul
          className={hamburgerClicked ? `nav-menu active` : `nav-menu`}
          onClick={onHamburgerClick}
        >
          {isAuthenticated ? authLink : ""}
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
