import { httpRequest , updateHttpRequest} from "@/util/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

/**
 * 비동기 액션 - 로그인
 */
export const login = createAsyncThunk(`auth/login`, async (arg) => {
  try{
  const info = { mail: arg.email, password: arg.password };
  const response = await httpRequest.post(`access/loginCognito`, info, {
    baseURL: ip_config.clientUrl,
  });

  const { jwtToken } = response.data;

      // JWT 토큰을 sessionStorage에 저장
      if (jwtToken ) {
        const jwtTokenString = jwtToken.split('Token=')[1].split(';')[0];

        sessionStorage.setItem('id_token', jwtTokenString);
        updateHttpRequest();
      }
  return response.data;
  }catch(error){
    console.log(error);
    return Promise.reject({
      status: error.response ? error.response.status : 500,
      message: error.message,
    });
  }
});



/**
 * 비동기 액션 - 로그아웃(서버히스토리 기록용)
 */
export const logout = createAsyncThunk(`auth/logout`, async () => {
  const response = await httpRequest.post(
    `access/logout`,
    {},
    {
      baseURL: ip_config.clientUrl,
    }
  );
  return response;
});

/**
 * auth 슬라이스(리듀서+액션)
 */
export const authSlice = createSlice({
  name: `auth`,
  initialState: {
    isAuthenticated: sessionStorage.getItem(`ipkk_user`) ? true : false,
    user: sessionStorage.getItem(`ipkk_user`) !== undefined ? JSON.parse(sessionStorage.getItem(`ipkk_user`)) : null,
    isLoading: false,
    error: null,
  },

  reducers: {
    /* 동기 처리 */
    quitLogout: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
      state.error = null;
    },
  },

  extraReducers: {
    /*  비동기 처리 */
    [login.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [login.fulfilled]: (state, action) => {
      sessionStorage.setItem(`ipkk_user`, JSON.stringify(action.payload.data));
      state.isAuthenticated = true;
      state.user = action.payload.data;
      state.isLoading = false;
      state.error = null;
      //window.location.href="/transProgressInfo"
      
    },
    [login.rejected]: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
      state.error = "이메일 또는 비밀번호를 확인해주세요.";
    },

    [logout.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [logout.fulfilled]: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
      state.error = null;
    },
    [logout.rejected]: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
      state.isAuthenticated = false;
      state.user = null;
      state.isLoading = false;
      state.error = `로그아웃 중 에러가 발생하였습니다.`;
    },
  },
});

export const { quitLogout } = authSlice.actions;

export default authSlice;
