import { handleResetPaginationActive } from "@/util/common";
import React from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "./PaypalPlanMngList.scss";

const PaypalPlanMngList = ({
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">플랜관리</div>
      </div>
      <div className="page-contents">
        <div className="board-contents-list__search-form mb-20" style={{minHeight:"50px"}}>
          <button className={`btn btn-s`}>
            <Link to="/plan/paypalPlanMngDetail/0">플랜 등록</Link>
          </button>
        </div>
        <div className="table-pagination-container ">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>플랜명</th>
                  <th>플랜가용량</th>
                  <th>구독가격</th>
                  <th>수정일시</th>
                  <th>구독연결</th>
                  <th>구독유무</th>
                  <th>상세보기</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={7}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`boardContents-tbody-${i}`}>
                      <td>{post.name}</td>
                      <td>{post.charCount}</td>
                      <td>{post.price}</td>
                      <td>{formatDate(post.updateAt)}</td>
                      <td>{post.joinYn === "1" ? "연결" : "미연결"}</td>
                      <td>{post.hasSubscription === "Y" ? "존재" : "미존재"}</td>
                      <td><Link
                          to={{
                            pathname: `/plan/paypalPlanMngDetail/${post.planId}`,
                          }}
                          className="text-kk text-bold font-size-15"
                        >
                          상세보기
                        </Link></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaypalPlanMngList;
