import CommonModal from "@/commonModule/Modal/CommonModal";
import { calculateCash, commafy, httpRequest } from "@/util/common";
import React, { Component } from "react";
import EditUserInfo from "./EditUserInfo";

class EditUserInfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {
        name: "",
        email: "",
        hphone: "",
        phoneNo: "",
        emailAuth: "",
        agreeAd: "N", // 유저 상세조회에서 agreeAd 데이터 내려주세요.
        agreeServiceUse: "",
        agreePersonalInfo: "",
        agree14over: "",
        adminRequest: "",
        adminYn: "",
        member_type: "",
        company: "",
        deleteYn:"",
        schema:"",
      },
      openAddCash: false,
      cashAmount: 0,
      memo: "",
    };
  }

  componentDidMount() {
    this.fetchUserInfo();
  }

  render() {
    const { userInfo, openAddCash, cashAmount, memo } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return (
      <div>
        {" "}
        <EditUserInfo
          id={id}
          userInfo={userInfo}
          onChangeEmailAuth={this.handleChangeEmailAuth}
          onEditUserInfo={this.handleEditUserInfo}
          onDeleteUser={this.handleDeleteUser}
          onClickOpenAddCash={this.handleClickOpenAddCash}
        />
        <CommonModal
          id={"addCash"}
          isOpen={openAddCash}
          title={"캐시 관리"}
          className={"bg-primary"}
          style={{ width: 800 }}
          body={
            <div>
              <div className="form-group flex-direction-row align-items-center">
                <label>캐시</label>
                <input
                  className={"form-control w-one-five"}
                  type="text"
                  name="cashAmount"
                  placeholder="0"
                  value={cashAmount}
                  onChange={this.handleChangeCashAmount}
                  onFocus={this.handleFocusCashAmount}
                  onBlur={this.handleBlurCashAmount}
                />{" "}
                <img
                  src="/images/kcash_ico.png"
                  alt="cash-image"
                  style={{ width: 20 }}
                  className="ml-5"
                />
              </div>
              <div className="form-group flex-direction-row align-items-center">
                <label>메모</label>
                <input
                  className={"form-control w-custom-1"}
                  type="text"
                  name="memo"
                  placeholder="메모"
                  value={memo}
                  onChange={this.handleChangeMemo}
                />{" "}
              </div>
            </div>
          }
          footer={
            <div className={"d-flex justify-content-end"}>
              <button className={"btn btn-s"} onClick={this.handleAddCash}>
                <em className="fa fa-plus mr-5"/>
                지급하기
              </button>
              <button className={"btn btn-s bg-danger"} onClick={this.handleSubtractCash}>
                <em className="fa fa-minus mr-5"/>
                차감하기
              </button>
              <button
                  className={"btn btn-s bg-grey"}
                  onClick={this.handleClickCloseAddCash}
              >
                취소
              </button>
            </div>
          }
          onClickCloseModal={this.handleClickCloseAddCash}
        />
      </div>
    );
  }

  /**
   * 유저정보 가져오기
   */
  fetchUserInfo = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    try {
      const { data } = await httpRequest.get(`user/${id}`);

      this.setState({
        ...this.state,
        userInfo: {
          ...this.state.userInfo,
          name: data.name,
          email: data.email,
          hphone: data.hphone,
          phoneNo: data.phone_no,
          emailAuth: data.email_auth,
          agreeAd: data.agree_ad,
          agreeServiceUse: data.agree_service_use,
          agreePersonalInfo: data.agree_personal_info,
          agree14over: data.agree_14over,
          adminYn: data.adminYn,
          member_type: data.member_type,
          company: data.company,
          deleteYn: data.deleteYn,
          schema: data.schema,
        },
      });
    } catch (err) {
      this.props.history.push("/error");
    }
  };

  /**
   * 사용자정보 인증여부 셀렉트박스 변경하기
   * @param {*} e
   */
  handleChangeEmailAuth = (e) => {
    this.setState({
      ...this.state,
      userInfo: { ...this.state.userInfo, [e.target.name]: e.target.value },
    });
  };

  /**
   * 회원정보(이메일인증여부) 수정 요청하기
   */
  handleEditUserInfo = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { emailAuth } = this.state.userInfo;
    const { adminYn } = this.state.userInfo;
    const { schema } = this.state.userInfo;
    try {
      if (confirm("사용자 정보를 수정하시겠습니까?")) {
        await httpRequest.put(`user/${id}`, { mailAuth: emailAuth , adminYn:adminYn, schema: schema});
        alert("정상적으로 수정되었습니다.");
      }
    } catch (err) {
      alert("수정 실패하였습니다.");
    }
  };

  /**
   * 회원 삭제하기
   */
  handleDeleteUser = async () => {
    const {
      params: { id },
    } = this.props.match;

    const { schema } = this.state.userInfo;

    try {
      if (confirm("회원을 삭제하시겠습니까?")) {
        await httpRequest.delete(`user/${id}`, { data: {schema:schema} });
        alert("정상적으로 삭제되었습니다.");
        this.props.history.push("/user/userMng");
      }
    } catch (err) {
      alert("삭제 실패하였습니다.");
    }
  };

  /**
   * 캐시지급 팝업 열기
   */
  handleClickOpenAddCash = () => {
    document.body.style.overflow = "hidden";
    this.setState({
      openAddCash: true,
    });
  };

  /**
   * 캐시지급 팝업 닫기
   */
  handleClickCloseAddCash = () => {
    document.body.style.overflow = "unset";
    this.setState({
      openAddCash: false,
    });
  };

  /**
   * cashAmount 인풋 변경하기
   * @param {*} e
   */
  handleChangeCashAmount = (e) => {
    const { value } = e.target;
    if (isNaN(value)) return;
    this.setState({ ...this.state, cashAmount: value * 1 });
  };

  /**
   * cashAmount 인풋 포커스 시 콤마 없애고 숫자로 바꿔주기
   * @returns
   */
  handleFocusCashAmount = () => {
    const { cashAmount } = this.state;
    if (cashAmount === 0) {
      this.setState({ ...this.state, cashAmount: "" });
      return;
    }
    this.setState({
      ...this.state,
      cashAmount: parseInt(cashAmount.replace(/,/gi, "")),
    });
  };

  /**
   * cashAmount 인풋 블러 시 다시 콤마처리하기
   * @param {*} e
   * @returns
   */
  handleBlurCashAmount = (e) => {
    const { cashAmount } = this.state;
    if (cashAmount === "") {
      this.setState({ ...this.state, cashAmount: 0 });
      return;
    }
    this.setState({ ...this.state, cashAmount: commafy(cashAmount) });
  };

  /**
   * 메모 인풋 변경하기
   * @param {*} e
   */
  handleChangeMemo = (e) => {
    this.setState({ ...this.state, memo: e.target.value });
  };

  /**
   * 캐시 지급하기
   */
  handleAddCash = async () => {
    const { cashAmount, memo } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { schema } = this.state.userInfo;
    const cash = cashAmount && parseInt(cashAmount.replace(/,/gi, ""));

    try {
      if (confirm(`${commafy(cash)}캐시를 지급하시겠습니까?`)) {
        await httpRequest.post(`point/${id}`, {
          pointType: "0",
          point: cash,
          memo,
          schema:schema,
        });
        alert("캐시가 정상적으로 지급되었습니다.");
        window.location.reload();
      }
    } catch (err) {
      alert("캐시 지급에 실패하였습니다.");
    }
  };

  /**
   * 캐시 차감하기
   */
  handleSubtractCash = async () => {
    const { cashAmount, memo } = this.state;
    const {
      match: {
        params: { id },
      }
    } = this.props;
    const cash = cashAmount && parseInt(cashAmount.replace(/,/gi, ""));
    const { schema } = this.state.userInfo;

    try {
      if (confirm(`${commafy(cash)}캐시를 차감하시겠습니까?`)) {
        await httpRequest.post(`point/${id}`, {
          pointType: "1",
          point: cash,
          memo,
          schema:schema,
        });
        alert("캐시가 정상적으로 차감되었습니다.");
        window.location.reload();
      }
    } catch (err) {
      alert("캐시 차감에 실패하였습니다.");
    }
  }
}

export default EditUserInfoContainer;
