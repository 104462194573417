import Calendar from "@/commonModule/Calendar/Calendar";
import RadioBtn from "@/commonModule/RadioBtn/RadioBtn";
import {
  commafy,
  handleResetPaginationActive,
  pickBadgeStyle,
} from "@/util/common";
import React from "react";
import ReactPaginate from "react-paginate";
import "./TransReqFailMng.scss";

const TransReqFailMng = ({
  monthTermList,
  onChangeMonthTerm,
  fromDate,
  endDate,
  setFromDate,
  setEndDate,
  orderNo,
  askerId,
  askerName,
  onChangeFieldValue,
  onCheckbox,
  onCheckboxAll,
  onResendByIds,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">번역 요청 지연 목록</div>
      </div>
      <div className="page-contents">
        {" "}
        <div className="trans-req-fail-mng__search-form mb-20">
          <div className="trans-req-fail-mng__search-form-top mb-5">
            {" "}
            <RadioBtn
              name={`monthTerm`}
              btnList={monthTermList}
              className={`mr-20 month-term-radio`}
              callback={onChangeMonthTerm}
            />
            <Calendar
              fromDate={fromDate}
              setFromDate={setFromDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className="trans-req-fail-mng__search-form-bottom">
            <input
              className={"form-control orderNo mr-20"}
              type="text"
              name="orderNo"
              placeholder="주문번호"
              value={orderNo}
              onChange={onChangeFieldValue}
            />
            <input
              className={"form-control askerId mr-20"}
              type="text"
              name="askerId"
              placeholder="요청자 ID"
              value={askerId}
              onChange={onChangeFieldValue}
            />
            <input
              className={"form-control askerName mr-20"}
              type="text"
              name="askerName"
              placeholder="요청자명"
              value={askerName}
              onChange={onChangeFieldValue}
            />

            <button
              className="btn btn-s"
              onClick={() => {
                onFetchPage(1);
                handleResetPaginationActive();
              }}
            >
              조회
            </button>
            <button
              onClick={onResendByIds}
              className={`btn btn-s ${
                currentPosts.some((post) => post.isSelected) ? "" : "disabled"
              }`}
            >
              <i className="fas fa-sync-alt mr-5"></i>
              재발송
            </button>
          </div>
        </div>
        <div className="table-pagination-container ">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>
                    <div className="c-checkbox">
                      <label>
                        <input
                          type="checkbox"
                          className="check"
                          name="checkAll"
                          onChange={onCheckboxAll}
                        />
                        <span className="fa fa-check" />
                      </label>
                    </div>
                  </th>
                  <th>요청번호</th>
                  <th>주문번호</th>
                  <th>내용</th>
                  <th>요청자정보</th>
                  <th>단위</th>
                  <th>금액</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={7}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`transReqFailMng-tbody-${i}`}>
                      <td>
                        <div className="c-checkbox">
                          <label>
                            <input
                              type="checkbox"
                              className="check"
                              name="check"
                              checked={post.isSelected}
                              onChange={() => onCheckbox(post)}
                            />
                            <span className="fa fa-check" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <span>{post.trans_req_idx}</span>
                      </td>

                      <td className="line-height-6">
                        <div>{post.order_no}</div>
                        <div>
                          <small>{`(${post.req_dttm})`}</small>
                        </div>
                      </td>
                      <td className="d-flex justify-content-space-between pd-20">
                        <div className="text-align-start line-height-two">
                          <div>{post.trans_coverage_name}</div>
                          <div className="text-bold">{post.doc_no}</div>
                        </div>
                        <div className="text-align-end line-height-two ml-50">
                          <span
                            className={`badge ${pickBadgeStyle(
                              post.trans_state
                            )}`}
                          >
                            {post.trans_state_name}
                          </span>
                          <div>
                            <span className="text-bold mr-5">
                              {" "}
                              {post.trans_type_name}
                            </span>
                            <span>
                              {" "}
                              {post.org_lang}{" "}
                              <em className="fas fa-arrow-right"></em>{" "}
                              {post.trans_lang}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="line-height-6">
                        <div>{post.req_user_name}</div>
                        <div>{post.req_user_email}</div>
                        <small>{post.req_user_phonenumber}</small>
                      </td>
                      <td>
                        <span>{commafy(post.char_count)}단어</span>
                      </td>
                      <td>
                        <span>{commafy(post.charge_point)}캐시</span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransReqFailMng;
