import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import PaypalPlanMngList from "./PaypalPlanMngList";
import * as common from "../../util/common";

class PaypalPlanMngListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardIdx: 1,
      searchTitle: "",
      planContents: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchPlanContents();
  }

  render() {
    const { boardIdx, searchTitle, planContents, postsPerPage, total } =
      this.state;
    return (
      <PaypalPlanMngList
        boardIdx={boardIdx}
        searchTitle={searchTitle}
        currentPosts={planContents}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchPlanContents}
      />
    );
  }

  /**
   * plan 리스트 받아오기
   */
  fetchPlanContents = async (number = 1) => {
    const {  postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `/plan/paypal/list?page=${number}&size=${postsPerPage}`
      );
      this.setState({ ...this.state, planContents: items, total });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        common.logout();
      }
      history.push("/error");
    }
  };

  /**
   * 검색제목 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default PaypalPlanMngListContainer;
