import RadioBtn from "@/commonModule/RadioBtn/RadioBtn";
import React, { Fragment } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "./TransReqMng.scss";
import {
  commafy,
  handleResetPaginationActive,
  pickBadgeStyle,
} from "@/util/common";
import Calendar from "@/commonModule/Calendar/Calendar";

const TransReqMng = ({
  monthTermList,
  onChangeMonthTerm,
  fromDate,
  endDate,
  setFromDate,
  setEndDate,
  orderNo,
  askerId,
  askerName,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
  onFileDownload,
  onOriginFileDownload,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">번역요청관리</div>
      </div>
      <div className="page-contents">
        {" "}
        <div className="trans-req-mng__search-form mb-20">
          <div className="trans-req-mng__search-form-top mb-5">
            {" "}
            <RadioBtn
              name={`monthTerm`}
              btnList={monthTermList}
              className={`mr-20 month-term-radio`}
              callback={onChangeMonthTerm}
            />
            <Calendar
              fromDate={fromDate}
              setFromDate={setFromDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </div>
          <div className="trans-req-mng__search-form-bottom">
            <input
              className={"form-control mr-20"}
              type="text"
              name="orderNo"
              placeholder="주문번호"
              value={orderNo}
              onChange={onChangeFieldValue}
            />
            <input
              className={"form-control mr-20"}
              type="text"
              name="askerId"
              placeholder="요청자 ID"
              value={askerId}
              onChange={onChangeFieldValue}
            />
            <input
              className={"form-control mr-20"}
              type="text"
              name="askerName"
              placeholder="요청자명"
              value={askerName}
              onChange={onChangeFieldValue}
            />

            <button
              className="btn btn-s"
              onClick={() => {
                onFetchPage(1);
                handleResetPaginationActive();
              }}
            >
              조회
            </button>
          </div>
        </div>
        <div className="table-pagination-container ">
          <div className={`table-container trans-req-mng__table-container`}>
            {" "}
            <table
              className={`${
                currentPosts && currentPosts.length === 0 ? "display-table" : ""
              }`}
            >
              <thead>
                <tr>
                  <th>요청번호</th>
                  <th>주문번호</th>
                  <th>내용</th>
                  <th>요청자정보</th>
                  <th>단위</th>
                  <th>금액</th>
                  <th>납품일시</th>
                  <th>다운로드 기간</th>
                  <th>다운로드</th>
                  <th>원본 다운로드</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={12}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`transReqMng-tbody-${i}`}>
                      <td>
                        <span>{post.trans_req_idx}</span>
                      </td>

                      <td className="line-height-6">
                        <div>{post.order_no}</div>
                        <div>
                          <small>{`(${post.req_dttm})`}</small>
                        </div>
                      </td>
                      <td className="d-flex justify-content-space-between pd-20">
                        <div className="text-align-start line-height-two">
                          <div>{post.trans_coverage_name}</div>
                          <div className="text-bold">
                            {post.doc_no.length > 25
                              ? `${post.doc_no.slice(0, 25)}...`
                              : post.doc_no}
                          </div>
                        </div>
                        <div className="text-align-end line-height-two ml-40">
                          <span
                            className={`badge ${pickBadgeStyle(
                              post.trans_state
                            )}`}
                          >
                            {post.trans_state_name}
                          </span>
                          <div>
                            <span className="text-bold mr-5">
                              {" "}
                              {post.trans_type_name}
                            </span>
                            <span>
                              {" "}
                              {post.org_lang}{" "}
                              <em className="fas fa-arrow-right"></em>{" "}
                              {post.trans_lang}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="line-height-6">
                        <div>{post.req_user_name}</div>
                        <div>{post.req_user_email}</div>
                        <small>{post.req_user_phonenumber}</small>
                      </td>
                      <td>
                        <span>{commafy(post.char_count)} 자</span>
                      </td>
                      <td>
                        <span>{commafy(post.charge_point)} 캐시</span>
                      </td>
                      <td>
                        <span>{post.trans_end_dttm}</span>
                      </td>
                      <td>
                        <span> ~ {post.down_end_date}</span>
                      </td>
                      <td>
                        {/*     결과물 다운로드 영역 */}
                        <div>
                          {post.trans_state === "E" &&
                          post.file_ext.toUpperCase() === "PDF" ? (
                            <Fragment>
                              <Link
                                to="#"
                                onClick={() =>
                                  onFileDownload(
                                    post.down_end_date,
                                    post.trans_req_idx,
                                    post.document_id,
                                    post.file_name,
                                    "PDF"
                                  )
                                }
                              >
                                <em className="far fa-file-pdf text-danger ml-5"></em>
                              </Link>
                              <Link
                                to="#"
                                onClick={() =>
                                  onFileDownload(
                                    post.down_end_date,
                                    post.trans_req_idx,
                                    post.document_id,
                                    post.file_name,
                                    "DOCX"
                                  )
                                }
                              >
                                <em className="far fa-file-word text-primary ml-5"></em>
                              </Link>
                            </Fragment>
                          ) : null}
                          {post.trans_state === "E" &&
                          post.file_ext.toUpperCase() === "DOCX" ? (
                            <Link
                              to="#"
                              onClick={() =>
                                onFileDownload(
                                  post.down_end_date,
                                  post.trans_req_idx,
                                  post.document_id,
                                  post.file_name,
                                  "DOCX"
                                )
                              }
                            >
                              <em className="far fa-file-word text-primary ml-5"></em>
                            </Link>
                          ) : null}
                        </div>
                      </td>
                      <td>
                        {/*     원본 다운로드 영역 */}
                        {post.trans_req_type === "N" ? (
                          <Link
                            to="#"
                            onClick={() =>
                              onOriginFileDownload(
                                post.down_end_date,
                                post.org_file_idx
                              )
                            }
                          >
                            {" "}
                            <em className="far fa-file-word"></em>
                          </Link>
                        ) : null}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransReqMng;
