import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import EditFaqContents from "./EditFaqContents";

class EditFaqContentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faqType: "0",
      publishYn: "Y",
      title: "",
      contents: "",
      errors: {
        title: "",
        contents: "",
      },
    };
  }

  componentDidMount() {
    this.fetchFaqContent();
  }

  render() {
    const { faqType, publishYn, title, contents, errors } = this.state;
    return (
      <EditFaqContents
        faqType={faqType}
        publishYn={publishYn}
        title={title}
        contents={contents}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onChangeContents={this.handleChangeContents}
        onEditFaq={this.handleEditFaq}
        onDeleteFaq={this.handleDeleteFaq}
      />
    );
  }

  /**
   * faqContent 받아오기
   */
  fetchFaqContent = async () => {
    const {
      params: { id },
    } = this.props.match;
    try {
      const {
        data: { content },
      } = await httpRequest.get(`board/faq/${id}`);
      this.setState({
        ...this.state,
        faqType: content.faq_type,
        publishYn: content.publish_yn,
        title: content.title,
        contents: content.contents,
      });
    } catch (err) {
      this.props.history("/error");
    }
  };

  /**
   * 게시여부,질문제목 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * ckEditor 내용 변경하기
   * @param {*} data
   */
  handleChangeContents = (data) => {
    this.setState({ ...this.state, contents: data });
  };

  /**
   * 유효성검사하기
   * @returns
   */
  validate = () => {
    let validated = true;
    const errors = {};
    const { title, contents } = this.state;

    if (!title) {
      errors.title = "질문을 입력해주세요";
      validated = false;
    }
    if (!contents) {
      errors.contents = "답변을 입력해주세요";
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * FAQ 수정 요청하기
   */
  handleEditFaq = async () => {
    const { publishYn, title, contents } = this.state;
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (contents.length > 20000) {
      alert("20,000자 이하로 입력해주세요.");
      return;
    }
    if (this.validate()) {
      try {
        const formData = new FormData();

        formData.append("publishYn", publishYn);
        formData.append("title", title);
        formData.append("contents", contents);

        await httpRequest.put(`board/faq/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        alert("정상적으로 수정되었습니다.");
        this.props.history.push("/board/faqContents");
      } catch (err) {
        alert("게시물 수정에 실패하였습니다.");
      }
    }
  };

  /**
   * FAQ 삭제 요청하기
   */
  handleDeleteFaq = async () => {
    const {
      params: { id },
    } = this.props.match;
    try {
      if (confirm("삭제하시겠습니까?")) {
        await httpRequest.delete(`board/faq/${id}`, { data: {} });
        alert("정상적으로 삭제되었습니다.");
        this.props.history.push("/board/faqContents");
      }
    } catch (err) {
      alert("삭제 실패하였습니다.");
    }
  };
}

export default EditFaqContentsContainer;
