import React from "react";
import { Link } from "react-router-dom";
import "./PlanCompanyDetail.scss";
import { commafy, addNumberHypen } from "@/util/common";

const PlanCompanyDetail = ({ 
  chosen,
  onClickCloseModal,
  sendCompany,
}) => {
  return (
    <div className="page-container">
      <div className="page__title">요금제 현황 상세 - {Object.values(sendCompany)}</div>
      <div className="divider"></div>
      <div className={"detail-preview table-pagination-container"}>
        <div className={"d-flex w-full table-container"}>
          <table className="table table-bordered w-full">
            <thead>
              <tr>
                <th style={{ width: 100 }} className="text-center">
                  요청일시
                </th>
                <th style={{ width: 120 }} className="text-center">
                  내용
                </th>
                <th style={{ width: 80 }} className="text-center">
                  요청자
                </th>
                <th style={{ width: 100 }} className="text-center">
                  단어수
                </th>
                <th style={{ width: 100 }} className="text-center">
                  캐시 사용
                </th>
                <th style={{ width: 100 }} className="text-center">
                  추가 결제(원)
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(chosen) && Object.values(chosen).length === 0 && (
                <tr stlye={{ alignItem: "center" }}>
                  <td colSpan={7}>검색결과가 없습니다.</td>
                </tr>
              )}
              {Object.values(chosen) &&
                Object.values(chosen).length > 0 &&
                Object.values(chosen).map((items, i) => (
                  <tr key={`detailPreview-${i}`}>
                    <td className="line-height-5">{items.req_dttm}</td> {/* 요청일시 */}
                    <td className="d-flex justify-content-space-between">
                      <div className="text-align-start line-height-two">
                        <div>{items.trans_coverage_name}</div>
                        <div className="text-bold">{items.document_no}</div>
                      </div>
                      <div className="text-align-end line-height-two">
                        <span
                          className={`badge ${pickBadgeStyle(items.trans_state)}`}
                        >
                          {items.trans_state_name}
                        </span>
                        <div>
                          <span className="text-bold mr-5">
                            {items.trans_type_name}
                          </span>
                          <span>
                            {" "}
                            {items.org_lang}{" "}
                            <em className="fas fa-arrow-right"></em>{" "}
                            {items.trans_lang}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="line-height-5">
                      <div>{items.name}</div>
                      <div className="mt-5">
                        <small>{`(${items.mail})`}</small>
                      </div>
                      <div>{addNumberHypen(items.hphone)}</div>
                    </td>{/* 요청자 */}
                    <td className="line-height-5">{commafy(items.char_count)}</td> {/* 단어수 */}
                    <td className="line-height-5">{commafy(items.pointUsage)}</td> {/* 캐시 사용 */}
                    <td className="line-height-5">{commafy(items.payUsage)}</td> {/* 추가 결제(원) */}
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </div>
    </div>
  );
};

function pickBadgeStyle(transState) {
  switch (transState) {
    case "H":
      return "badge-secondary";
    case "A":
      return "badge-info";
    case "W":
      return "badge-warning";
    case "P":
      return "badge-primary";
    case "E":
      return "badge-success";
    default:
      break;
  }
}

export default PlanCompanyDetail;
