import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";

import "./styles/font.scss";
import "./styles/app.scss";
import "./styles/form.scss";
import "./styles/editor.scss";
import "./styles/contents.scss";

/* 레이아웃  컴포넌트 */
import MainLayout from "./layout/MainLayout/MainLayout";

/* 커스텀 리액트 라우터*/
import AppRoute from "./util/AppRoute";
import PublicRoute from "./util/PublicRoute";
import PrivateRoute from "./util/PrivateRoute";

/* route 폴더 */
import Error from "./routes/Error/Error";
import HelpGuides from "./routes/Help/Help";

/* serviceModule 폴더 */

import LoginContainer from "./serviceModule/Login/LoginContainer";
import TransProgressInfoContainer from "./serviceModule/TransProgressInfo/TransProgressInfoContainer";
import TransReqMngContainer from "./serviceModule/TransReqMng/TransReqMngContainer";
import TransReqFailMngContainer from "./serviceModule/TransReqFailMng/TransReqFailMngContainer";
import BoardContentsListContainer from "./serviceModule/BoardContentsList/BoardContentsListContainer";
import FaqContentsListContainer from "./serviceModule/FaqContentsList/FaqContentsListContainer";
import UserMngListContainer from "./serviceModule/UserMngList/UserMngListContainer";
import CreateBoardContentsContainer from "./serviceModule/CreateBoardContents/CreateBoardContentsContainer";
import EditBoardContentsContainer from "./serviceModule/EditBoardContents/EditBoardContentsContainer";
import CreateFaqContentsContainer from "./serviceModule/CreateFaqContents/CreateFaqContentsContainer";
import EditFaqContentsContainer from "./serviceModule/EditFaqContents/EditFaqContentsContainer";
import EditUserInfoContainer from "./serviceModule/EditUserInfo/EditUserInfoContainer";

/** 2024-10-15 추가 */
import PlanMngListContainer from "./serviceModule/PlanMngList/PlanMngListContainer";
import ChargeMngListContainer from "./serviceModule/ChargeMngList/ChargeMngListContainer";
import UsageMngListContainer from "./serviceModule/UsageMngList/UsageMngListContainer";
import PaymentMngListContainer from "./serviceModule/PaymentMngList/PaymentMngListContainer";

import PlanCompanyListContainer from "./serviceModule/PlanMngList/PlanCompanyListContainer";
import PaymentMonthMngListContainer from "./serviceModule/PaymentMonthMngList/PaymentMonthMngListContainer";

import PaypalPlanMngListContainer from "./serviceModule/PaypalPlanMng/PaypalPlanMngContainer";
import EditPaypalContentsContainer from "./serviceModule/EditPaypalContents/EditPaypalContentsContainer";

/* 1:1문의 추가 */ 
import InquiryContentsListContainer from "./serviceModule/InquiryContentsList/InquiryContentsListContainer";
import EditInquiryContentsContainer from "./serviceModule/EditInquiryContents/EditInquiryContentsContainer";
import InquiryDtlContainer from "./serviceModule/InquiryDtl/InquiryDtlContainer";


class App extends Component {
  render() {
    return (
      <Switch>
        {/* 루트 - 로그인 */}
        <PublicRoute
          exact
          path="/"
          layout={MainLayout}
          component={LoginContainer}
        />

        <PrivateRoute
          path="/help/guides"
          layout={MainLayout}
          component={HelpGuides}
        />

        {/* 에러발생 시 화면 */}
        <PrivateRoute path="/error" layout={MainLayout} component={Error} />

        {/* 로그인 후 접근가능화면 */}

        {/* 번역현황요약 */}
        <PrivateRoute
          path="/transProgressInfo"
          layout={MainLayout}
          component={PlanCompanyListContainer}
          // component={TransProgressInfoContainer}
        />

        {/* 번역요청관리*/}
        <PrivateRoute
          path="/transReqMng"
          layout={MainLayout}
          component={TransReqMngContainer}
        />

        {/* 번역요청지연목록*/}
        <PrivateRoute
          path="/transReqFailMng"
          layout={MainLayout}
          component={TransReqFailMngContainer}
        />

        {/* 일반게시판 조회 / 등록 / 편집 */}
        <PrivateRoute
          exact
          path="/board/boardContents"
          layout={MainLayout}
          component={BoardContentsListContainer}
        />
        <PrivateRoute
          exact
          path="/board/view/createBoardContents"
          layout={MainLayout}
          component={CreateBoardContentsContainer}
        />
        <PrivateRoute
          exact
          path="/board/boardContents/:id"
          layout={MainLayout}
          component={EditBoardContentsContainer}
        />

        {/* FAQ 조회 / 등록 / 편집 */}
        <PrivateRoute
          exact
          path="/board/faqContents"
          layout={MainLayout}
          component={FaqContentsListContainer}
        />
        <PrivateRoute
          exact
          path="/board/view/createFaqContents"
          layout={MainLayout}
          component={CreateFaqContentsContainer}
        />
        <PrivateRoute
          exact
          path="/board/faqContents/:id"
          layout={MainLayout}
          component={EditFaqContentsContainer}
        />

        {/* 1:1 문의 목록 / 상세 / 편집 */}
        <PrivateRoute
          exact
          path="/board/inquiry"
          layout={MainLayout}
          component={InquiryContentsListContainer}
        />

        <PrivateRoute
          exact
          path="/board/inquiry/:id"
          layout={MainLayout}
          component={InquiryDtlContainer}
        /> 

        <PrivateRoute
          exact
          path="/board/inquiryContents/:id"
          layout={MainLayout}
          component={EditInquiryContentsContainer}
        />

        {/* 사용자관리 > 조회 / 편집*/}
        <PrivateRoute
          exact
          path="/user/userMng"
          layout={MainLayout}
          component={UserMngListContainer}
        />
        <PrivateRoute
          exact
          path="/user/userMng/:id"
          layout={MainLayout}
          component={EditUserInfoContainer}
        />
        <PrivateRoute
          exact
          path="/plan/planMng"
          layout={MainLayout}
          component={PlanMngListContainer}
        />
        <PrivateRoute
          exact
          path="/plan/planCompanyList"
          layout={MainLayout}
          component={PlanCompanyListContainer}
        />
        <PrivateRoute
          exact
          path="/charge/chargeMng"
          layout={MainLayout}
          component={ChargeMngListContainer}
        />
        <PrivateRoute
          exact
          path="/usage/usageMng"
          layout={MainLayout}
          component={UsageMngListContainer}
        />
        <PrivateRoute
          exact
          path="/payment/paymentMng"
          layout={MainLayout}
          component={PaymentMngListContainer}
        /> 

        <PrivateRoute
          exact
          path="/payment/paymentMonthMng"
          layout={MainLayout}
          component={PaymentMonthMngListContainer}
        />     
        {/* 플랜관리 */}
        <PrivateRoute
          exact
          path="/plan/paypalPlanMngList"
          layout={MainLayout}
          component={PaypalPlanMngListContainer}
        />

        <PrivateRoute
          exact
          path="/plan/paypalPlanMngDetail/:id"
          layout={MainLayout}
          component={EditPaypalContentsContainer}
        />

        <Redirect from="*" to="/" />
      </Switch>
    );
  }
}

export default App;
