import React from "react";
import { Link } from "react-router-dom";
import { handleResetPaginationActive } from "@/util/common";
import ReactPaginate from "react-paginate";

const UserMngList = ({
  userEmail,
  userName,
  memberType,
  adminYn,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">사용자관리</div>
      </div>
      <div className="page-contents">
        <div className="d-flex mb-20">
          <select
            className="select-box"
            id="memberType"
            name="memberType"
            value={memberType}
            onChange={onChangeFieldValue}
          > 
            <option value="">전체</option>
            <option value="1">일반사용자</option>
            <option value="2">일반사용자</option>
            <option value="3">기업관리자</option>
          </select>
          <input
            className={"form-control w-one-five mr-10"}
            type="text"
            name="userEmail"
            placeholder="사용자 이메일"
            value={userEmail}
            onChange={onChangeFieldValue}
          />
          <input
            className={"form-control w-one-three mr-10"}
            type="text"
            name="userName"
            placeholder="사용자 명"
            value={userName}
            onChange={onChangeFieldValue}
          />
          <button
            className="btn btn-s"
            onClick={() => {
              onFetchPage(1);
              handleResetPaginationActive();
            }}
          >
            조회
          </button>
        </div>
        <div className="d-flex mb-20">
          <select
            className="select-box"
            id="adminYn"
            name="adminYn"
            value={adminYn}
            onChange={onChangeFieldValue}
          >
            <option value="">전체</option>
            <option value="Y">승인</option>
            <option value="N">미승인</option>
          </select>
        </div>
        <div className="table-pagination-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>회원구분</th>
                  <th>사용자 명</th>
                  <th>이메일</th>
                  <th>가입일시</th>
                  <th>탈퇴유무</th>
                  <th>관리자승인</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={6}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`userMngList-tbody-${i}`}>
                      <td>
                        <span>{post.memberType === 1
                                ? "일반사용자"
                                : post.memberType === 2
                                ? post.adminYn == "Y"
                                  ? "기업관리자"
                                  : post.adminYn == "N"
                                  ? "기업사용자"
                                  : "err"
                                : "err"}</span>
                      </td>
                      <td>
                        <Link
                          to={`/user/userMng/${post.user_idx}`}
                          className="text-primary text-bold"
                        >
                          <span>{post.name}</span>
                        </Link>
                      </td>
                      <td>
                        <span>{post.email}</span>
                      </td>
                      <td>
                        <span>{post.reg_dttm}</span>
                      </td>
                      <td>
                        <span>{post.deleteYn == "Y" ? "탈퇴" : "미탈퇴" }</span>
                      </td>
                      <td>
                        <span>{post.adminYn == "Y" ? "승인" : "미승인"}</span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMngList;
