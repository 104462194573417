import React, { Component } from "react";
import { convertDateFormat, httpRequest, MONTH_TERM_INFO } from "@/util/common";
import TransReqFailMng from "./TransReqFailMng";

class TransReqFailMngContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 3);
    this.state = {
      orderNo: "",
      askerId: "",
      askerName: "",
      fromDate: d,
      endDate: new Date(),
      transReqFailMng: [],
      postsPerPage: 6,
      total: 0,
    };
    this.monthTermList = Object.keys(MONTH_TERM_INFO).map((key) => {
      const obj = MONTH_TERM_INFO[key];
      return {
        label: obj.label,
        value: obj.monthTerm,
        defaultChecked: key === `THREE_MONTH`,
      };
    });
  }

  componentDidMount() {
    this.fetchTransReqFailMng();
  }
  render() {
    const {
      orderNo,
      askerId,
      askerName,
      fromDate,
      endDate,
      transReqFailMng,
      postsPerPage,
      total,
    } = this.state;
    return (
      <TransReqFailMng
        monthTermList={this.monthTermList}
        onChangeMonthTerm={this.handleMonthTerm}
        fromDate={fromDate}
        endDate={endDate}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        orderNo={orderNo}
        askerId={askerId}
        askerName={askerName}
        onChangeFieldValue={this.handleChangeFieldValue}
        onCheckbox={this.handleCheckbox}
        onCheckboxAll={this.handleCheckboxAll}
        onResendByIds={this.handleResendByIds}
        currentPosts={transReqFailMng}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchTransReqFailMng}
      />
    );
  }

  /**
   * 번역요청지연목록 정보 가져오기(체크여부 추가)
   */
  fetchTransReqFailMng = async (number = 1) => {
    const { orderNo, askerId, askerName, fromDate, endDate, postsPerPage } =
      this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `translate/fail?page=${number}&size=${postsPerPage}&from=${convertDateFormat(
          fromDate
        )}&to=${convertDateFormat(
          endDate
        )}&orderNo=${orderNo}&userMail=${askerId}&userName=${askerName}`
      );
      const transReqFailMng = items.map((item) => {
        item.isSelected = false;
        return item;
      });
      this.setState({ ...this.state, transReqFailMng, total });
    } catch (err) {
      history.push("/error");
    }
  };

  /**
   * 보관함 리스트 중 체크박스 선택하기
   * @param {*} post
   */
  handleCheckbox = (post) => {
    const transReqFailMng = this.state.transReqFailMng.map((item) => {
      if (item.trans_req_idx === post.trans_req_idx) {
        return { ...item, isSelected: !item.isSelected };
      } else {
        return item;
      }
    });
    this.setState({ transReqFailMng });
  };

  /**
   * 체크박스 전체 선택하기
   * @param {*} e
   */
  handleCheckboxAll = (e) => {
    const value = e.target.checked;
    const transReqFailMng = this.state.transReqFailMng.map((item) => {
      item.isSelected = value;
      return item;
    });
    this.setState({ transReqFailMng });
  };

  /**
   * 선택 실패목록 재발송하기
   */
  handleResendByIds = async () => {
    let arrayIds = [];
    this.state.transReqFailMng.forEach((item) => {
      if (item.isSelected) {
        arrayIds.push(item.trans_req_idx);
      }
    });

    try {
      await httpRequest.post(
        "translate",
        { transReqIndices: arrayIds },
        { baseURL: ip_config.clientUrl }
      );
      alert("정상적으로 재발송 되었습니다.");
    } catch (err) {
      alert("재발송 실패하였습니다.");
    }
  };

  /**
   * 라디오버튼 선택 시 조회기간 변경하기
   * @param {*} e
   */
  handleMonthTerm = (e) => {
    const d = new Date();
    if (e.target.value === `3`) {
      d.setMonth(d.getMonth() - 3);
      this.setState({ ...this.state, fromDate: d });
    } else if (e.target.value === `6`) {
      d.setMonth(d.getMonth() - 6);
      this.setState({ ...this.state, fromDate: d });
    }
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ ...this.state, fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ ...this.state, endDate: date });
  };

  /**
   * 주문번호 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default TransReqFailMngContainer;
