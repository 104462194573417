import React from "react";
import { scrollMove } from "@/util/common";

const Help = () => {
    return (
        <div className="page-container">
            <div className="manual-wrap">
                <div className="manual-index-wrap">
                    <ul className="manual-index">
                        <li><p className="tit">관리자 매뉴얼</p></li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p1')}>1. 사용자관리</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() => scrollMove('p1-1')}>1.1 사용자관리</a></li>
                                <li><a href="javascript:;" onClick={() => scrollMove('p1-2')}>1.2 [기업관리자] 승인</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() => scrollMove('p2')}>2. 요금제 플랜 구독 현황</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() => scrollMove('p2-1')}>2.1 요금제 플랜 구독 현황 조회</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() => scrollMove('p3')}>3. [번역 가능 단어수] 관리</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() => scrollMove('p3-1')}>3.1 [번역 가능 단어수] 사용현황 </a></li>
                                <li><a href="javascript:;" onClick={() => scrollMove('p3-2')}>3.2 [번역 가능 단어수] 사용내역 </a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() => scrollMove('p4')}>4. 청구현황</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() => scrollMove('p4-1')}>4.1 청구현황 조회</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="manual-contents-wrap">
                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p1">1. 사용자관리</h3>
                            <h4 id="p1-1">1.1 사용자관리</h4>
                            <div className="manual-info-box">
                                <img src="/images/help/p1.png" />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>화면의 [사용자관리] 메뉴를 클릭하여 사용자관리 화면으로 이동합니다.</li>
                                        <li>[조회] 버튼을 클릭하여 IP킹콩에 등록된 회원의 목록을 조회합니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p1-2">1.2 [기업관리자] 승인</h4>
                            <div className="manual-info-box">
                                <img src="/images/help/p2.png" />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>가. 화면의 [사용자관리] 메뉴를 클릭하여 사용자관리 화면으로 이동합니다.</li>
                                        <li>나. [관리자승인]이 미승인 상태인 사용자의 사용자정보에서 [관리자승인]을 승인 상태로 변경한 후 저장하면 해당 사용자를 기업관리자 등록할 수 있습니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p2">2. 요금제 플랜 구독 현황</h3>
                            <h4 id="p2-1">2.1 요금제 플랜 구독 현황 조회</h4>
                            <div className="manual-info-box">
                                <img src="/images/help/p3.png" />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>화면의 [요금제 플랜 구독 현황] 메뉴를 클릭하여 요금제 플랜 구독 현황 조회 화면으로 이동합니다.</li>
                                        <li>
                                            현재 유효한 요금제 플랜 구독 목록을 조회할 수 있습니다.
                                    <ul className="d-list">
                                                <li>요금제 플랜별 가입현황을 조회합니다.</li>
                                                <li>요금제 플랜 상세 가입내역을 조회합니다.</li>
                                            </ul>
                                        </li>
                                        <li>회원 또는 요금제 플랜 항목을 선택하여 조회할 수 있습니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p3">3. [번역 가능 단어수] 관리</h3>
                            <h4 id="p3-1">3.1 [번역 가능 단어수] 사용현황</h4>
                            <div className="manual-info-box">
                                <img src="/images/help/p4.png" />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>화면의 [번역 가능 단어수] 메뉴를 클릭하여 [번역 가능 단어수] 조회 화면으로 이동합니다.</li>
                                        <li>
                                            전체 또는 회원별 [번역 가능 단어수] 의 사용현황을 조회합니다.
                                    <ul className="d-list">
                                                <li>[번역 가능 단어수] 는 가입된 요금제 플랜에 따라 할당됩니다. [요금제 변경] 이동</li>
                                                <li>[총 사용량] 현재 구독기간(월)에 변역 요청에 사용된 총 [번역 가능 단어수] 의 사용량을 조회합니다.</li>
                                                <li>[잔여 사용량] 은 번역 요청에 사용 가능한 [번역 가능 단어수]의 잔량을 조회합니다.</li>
                                            </ul>
                                        </li>
                                        <li>회원 또는 요금제 플랜 항목을 선택하여 조회할 수 있습니다.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p3-2">3.2 [번역 가능 단어수] 사용내역</h4>
                            <div className="manual-info-box">
                                <img src="/images/help/p5.png" />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>화면의 [번역 가능 단어수] 메뉴를 클릭하여 [번역 가능 단어수] 조회 화면으로 이동합니다.</li>
                                        <li>
                                            전체 또는 회원별 [번역 가능 단어수]의 사용내역을 조회합니다.
                                    <ul className="d-list">
                                                <li>번역 단어수 : 번역 요청한 문서의 번역 대상 총 단어수 입니다.</li>
                                                <li>[번역 가능 단어수] 사용량 : 요금제 플랜에 따라 할당된 [번역 가능 단어수]의 사용량 입니다.</li>
                                                <li>캐시 : [번역 가능 단어수] 소진에 따른 추가 비용에 대해 지급한 보유캐시 사용량입니다.</li>
                                                <li>단건결제 : [번역 가능 단어수] 소진에 따른 추가 비용에 대해 지급한 단건결제 금액입니다.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p4">4. 청구현황</h3>
                            <h4 id="p4-1">4.1 청구현황 조회</h4>
                            <div className="manual-info-box">
                                <img src="/images/help/p6.png" />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>화면의 [청구현황] 메뉴를 클릭하여 청구현황 조회 화면으로 이동합니다.</li>
                                        <li>청구기간을 입력한 후 [조회] 버튼을 클릭합니다.</li>
                                        <li>
                                            청구대상, 청구일자, 요금제에 따른 청구금액, 결제일자 등을 조회할 수 있습니다.
                                    <ul className="d-list">
                                                <li>청구금액은 가입된 요금제 플랜에 대한 정액금액을 청구합니다.</li>
                                                <li>[총 사용량] 현재 구독기간(월)에 변역 요청에 사용된 총 [번역 가능 단어수] 의 사용량을 조회합니다.</li>
                                                <li>요금제 플랜의 [번역 가능 단어수] 소진에 따른 따른 번역요청 추가 비용은 보유캐시 또는 단건 직접 결제를 통해 지급되며, 그 비용은 청구되지 않습니다.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Help;
