import React from "react";
import { Link } from "react-router-dom";
import "./Login.scss";

const Login = ({
  email,
  password,
  remember,
  onChangeFieldValue,
  onRemember,
  onSubmit,
  error,
}) => {
  return (
    <section className="login">
      <div className="mt-25 mb-25 text-align-center">SIGN IN TO CONTINUE.</div>
      <form className="login__form" onSubmit={onSubmit}>
        <div className="input-chaining mb-10">
          <input
            className="form-control"
            name="email"
            type="email"
            required
            placeholder="이메일을 입력해주세요"
            value={email}
            onChange={onChangeFieldValue}
          />
          <div className="append">
            <span className="fas fa-envelope dummy-btn bg-w h-full" />
          </div>
        </div>

        <div className="input-chaining mb-15">
          <input
            className="form-control"
            name="password"
            type="password"
            required
            placeholder="Password"
            value={password}
            onChange={onChangeFieldValue}
          />
          <div className="append">
            <span className="fas fa-lock dummy-btn bg-w h-full" />
          </div>
        </div>

        <div className="login__append">
          <div className="c-checkbox">
            <label>
              <input
                type="checkbox"
                className="check"
                onChange={onRemember}
                checked={remember}
              />
              <span className="fa fa-check" />
              <span>ID 기억하기</span>
            </label>
          </div>

          <div>
            <Link to="#">비밀번호 찾기</Link>
          </div>
        </div>
        <button className="btn w-full">Login</button>
      </form>

      <div className="login__notification text-align-center">
        <div className="validation-message mt-5">{error}</div>
        <p>아직 회원이 아닌가요?</p>
        <Link to="#">
          <button className={"btn bg-w w-full"}>가입하기</button>
        </Link>
      </div>
    </section>
  );
};

export default Login;
