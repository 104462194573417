import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditPaypalContents = ({
  planInfo,
  onChangeFieldValue,
  fetchPlanContent,
  errors,
  handleEditPlan,
}) => {
  const fileRef = useRef();
  const isDisabled = planInfo.hasSubscription === "Y"; 
  console.log("planinfo?" + JSON.stringify(planInfo))

  return (
    <div>
      <div className="card card-border-top w-full mt-20 mb-20">
        <div className="editor__header">
          <span>플랜 정보</span>
        </div>
        <div className="divider"></div>
        <div className="editor__main">
          <div className="mb-10">
            <div className="form-group flex-direction-row align-items-center">
              <label>플랜명</label>
              <input
                className="form-control"
                type="text"
                name="name"
                placeholder="플랜명을 입력해주세요."
                value={planInfo && planInfo.name || ''}  // 기본값을 빈 문자열로 설정
                onChange={onChangeFieldValue}
                disabled={isDisabled} 
              />
            </div>
            <div className="form-group flex-direction-row align-items-center">
              <label>구독가격</label>
              <input
                className="form-control"
                type="number"
                name="price"
                placeholder="플랜 구독 가격을 입력해주세요."
                value={planInfo && planInfo.price || ''}  // 기본값을 빈 문자열로 설정
                onChange={onChangeFieldValue}
                disabled={isDisabled} 
              />
            </div>
            <div className="form-group flex-direction-row align-items-center">
              <label>플랜가용량(단어)</label>
              <input
                className="form-control"
                type="number"
                name="charCount"
                placeholder="플랜 가용량을 입력해주세요."
                value={planInfo && planInfo.charCount || ''}  // 기본값을 빈 문자열로 설정
                onChange={onChangeFieldValue}
              />
            </div>
          </div>
        </div>
        
        
      </div>
      <div className="card card-border-top w-full mt-20 mb-20">
        <div className="editor__header">
          <span>구독 정보</span>
        </div>
        <div className="editor__main">
          <div className="mb-10">
            <div className="form-group flex-direction-row align-items-center">
              <label>플랜명</label>
              <input
                className="form-control"
                type="text"
                name="planName"
                placeholder="플랜명을 입력해주세요."
                value={planInfo && planInfo.planName || ''}  // 기본값을 빈 문자열로 설정
                onChange={onChangeFieldValue}
                disabled={isDisabled} 
              />
            </div>
            <div className="form-group flex-direction-row align-items-center">
              <label>구독주기</label>
              <div className="btn-radio-container mr-20 month-term-radio">
                <div className="btn-radio">
                  <input
                    type="radio"
                    id="monthTerm0"
                    name="intervalUnit"
                    value="MONTH"
                    checked={planInfo && planInfo.intervalUnit === "MONTH"}
                    onChange={onChangeFieldValue}
                    disabled={isDisabled} 
                  />
                  <label htmlFor="monthTerm0" 
                  style={{
                    backgroundColor: isDisabled ? "#d3d3d3" : "",  
                    color: isDisabled ? "#a9a9a9" : "",            
                    cursor: isDisabled ? "not-allowed" : "",      
                    border: isDisabled ? "1px solid #888888" : "", 
                  }}>
                    개월
                  </label>
                </div>
                <div className="btn-radio" >
                  <input
                    type="radio"
                    id="monthTerm1"
                    name="intervalUnit"
                    value="YEAR"
                    checked={planInfo && planInfo.intervalUnit === "YEAR"}
                    onChange={onChangeFieldValue}
                    disabled={isDisabled} 
                  />
                  <label htmlFor="monthTerm1" 
                  style={{
                    backgroundColor: isDisabled ? "#d3d3d3" : "",  
                    color: isDisabled ? "#a9a9a9" : "",            
                    cursor: isDisabled ? "not-allowed" : "",      
                    border: isDisabled ? "1px solid #c0c0c0" : "", 
                  }}
                  >연도</label>
                  
                </div>
              </div>
            </div>
            <div className="form-group flex-direction-row align-items-center">
                  <label>반복횟수</label>
                  <input
                    className="form-control"
                    type="number"
                    name="totalCycles"
                    placeholder="반복횟수를 입력해주세요 (0은 무기한)"
                    value={planInfo && planInfo.totalCycles !== undefined ? planInfo.totalCycles : ''}
                    onChange={onChangeFieldValue}
                    style={{width:"30%"}}
                    disabled={isDisabled} 
                    max={999}
                    maxLength={3}
                  />
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="editor__footer">
          <div>
            <a href="/plan/paypalPlanMngList">
              <button className="list-btn">
                <em className="fas fa-list-ul mr-5"></em>
                <strong>목록</strong>
              </button>
            </a>
          </div>
          <div>
            <button className="btn btn-s" onClick={handleEditPlan} disabled={isDisabled}
            style={{
              backgroundColor: isDisabled ? "#d3d3d3" : "",  
              color: isDisabled ? "#a9a9a9" : "",            
              cursor: isDisabled ? "not-allowed" : "",      
              border: isDisabled ? "1px solid #c0c0c0" : "", 
            }} >
              <em className="fa fa-check mr-5"></em>
              <strong>플랜 등록</strong>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default EditPaypalContents;
