import React, { Fragment } from "react";
import "./MainLayout.scss";
import NavbarContainer from "@/commonModule/Navbar/NavbarContainer";
import Footer from "@/commonModule/Footer/Footer";

const MainLayout = (props) => {
  return (
    <Fragment>
      <NavbarContainer {...props} />
      <div className="layout">{props.children}</div>
      <Footer {...props} />
    </Fragment>
  );
};

export default MainLayout;
