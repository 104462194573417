import React, { Component } from "react";
import { convertDateFormat, httpRequest, MONTH_TERM_INFO } from "@/util/common";
import TransReqMng from "./TransReqMng";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* import moment from "moment"; */

toast.configure();
class TransReqMngContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 3);
    this.state = {
      orderNo: "",
      askerId: "",
      askerName: "",
      fromDate: d,
      endDate: new Date(),
      transReqMng: [],
      postsPerPage: 6,
      total: 0,
    };
    this.monthTermList = Object.keys(MONTH_TERM_INFO).map((key) => {
      const obj = MONTH_TERM_INFO[key];
      return {
        label: obj.label,
        value: obj.monthTerm,
        defaultChecked: key === `THREE_MONTH`,
      };
    });
  }

  componentDidMount() {
    this.fetchTransReqMng();
  }

  render() {
    const {
      orderNo,
      askerId,
      askerName,
      fromDate,
      endDate,
      transReqMng,
      postsPerPage,
      total,
    } = this.state;

    return (
      <TransReqMng
        monthTermList={this.monthTermList}
        onChangeMonthTerm={this.handleMonthTerm}
        fromDate={fromDate}
        endDate={endDate}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        orderNo={orderNo}
        askerId={askerId}
        askerName={askerName}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={transReqMng}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchTransReqMng}
        onFileDownload={this.handleFileDownload}
        onOriginFileDownload={this.handleOriginFileDownload}
      />
    );
  }

  /**
   * 번역요청관리 정보 가져오기
   */
  fetchTransReqMng = async (number = 1) => {
    const { orderNo, askerId, askerName, fromDate, endDate, postsPerPage } =
      this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `translate?page=${number}&size=${postsPerPage}&from=${convertDateFormat(
          fromDate
        )}&to=${convertDateFormat(
          endDate
        )}&orderNo=${orderNo}&userMail=${askerId}&userName=${askerName}`
      );
      this.setState({ ...this.state, transReqMng: items, total });
    } catch (err) {
      history.push("/error");
    }
  };

  /**
   * 라디오버튼 선택 시 조회기간 변경하기
   * @param {*} e
   */
  handleMonthTerm = (e) => {
    const d = new Date();
    if (e.target.value === `3`) {
      d.setMonth(d.getMonth() - 3);
      this.setState({ ...this.state, fromDate: d });
    } else if (e.target.value === `6`) {
      d.setMonth(d.getMonth() - 6);
      this.setState({ ...this.state, fromDate: d });
    }
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ ...this.state, fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ ...this.state, endDate: date });
  };

  /**
   * 주문번호 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * pdf, word 결과물 다운로드 받기
   */
  handleFileDownload = async (
    endDate,
    transReqIdx,
    documentId,
    fileName,
    type
  ) => {
    /*   const isExpired =
      moment(endDate, "YYYY-MM-DD").diff(moment().format("YYYY[-]MM[-]DD")) < 0; */
    const url = `${
      ip_config.clientUrl
    }/file/download?requestId=${transReqIdx}&documentId=${documentId}&fileName=${fileName}&docx=${
      type === "PDF" ? false : true
    }`;

    /*   if (isExpired) {
      toast.error("다운로드가능 기한이 초과되었습니다.");
      return;
    } */
    try {
      const { data } = await httpRequest.get(url, { responseType: "blob" });
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName);
      document.body.append(link);
      link.click();
    } catch (err) {
      toast.error("다운로드 중 에러가 발생하였습니다.");
    }
  };

  /**
   * 원본 다운로드 받기
   * @param {*} endDate
   * @param {*} fileIdx
   */
  handleOriginFileDownload = async (endDate, fileIdx) => {
    /*   const isExpired =
      moment(endDate, "YYYY-MM-DD").diff(moment().format("YYYY[-]MM[-]DD")) < 0; */
    const url = `${ip_config.baseUrl}/file/${fileIdx}
    `;
    /*   if (isExpired) {
      toast.error("다운로드가능 기한이 초과되었습니다.");
      return;
    } */
    try {
      const { data } = await httpRequest.get(url, { responseType: "blob" });
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${fileIdx}.docx`);
      document.body.append(link);
      link.click();
    } catch (err) {
      toast.error("다운로드 중 에러가 발생하였습니다.");
    }
  };
}

export default TransReqMngContainer;
