import React from "react";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateFaqContents = ({
  faqType,
  publishYn,
  title,
  contents,
  errors,
  onChangeFieldValue,
  onChangeContents,
  onCreateFaq,
}) => {
  return (
    <div className="card card-border-top w-full mt-20 mb-20">
      <div className="editor__header">
        <span>FAQ 등록</span>
      </div>
      <div className="divider"></div>
      <div className="editor__main">
        <div className="mb-20">
          <div className="form-group flex-direction-row align-items-center">
            <label>질문유형</label>
            <select
              className="select-box"
              id="faqType"
              name="faqType"
              value={faqType}
              onChange={onChangeFieldValue}
            >
              <option value="">전체</option>
              <option value="1">회원정보</option>
              <option value="2">결제/환불</option>
              <option value="3">시스템/에러</option>
              <option value="4">사용법</option>
              <option value="5">기타</option>
            </select>
          </div>
          <p className="validation-message ml-90">{errors.faqType}</p>
        </div>

        <div className="form-group flex-direction-row align-items-center">
          <label>게시여부</label>
          <select
            className="select-box"
            id="publishYn"
            name="publishYn"
            value={publishYn}
            onChange={onChangeFieldValue}
          >
            <option value="Y">공개</option>
            <option value="N">비공개</option>
          </select>
        </div>
        <div>
          <div className="form-group flex-direction-row align-items-center">
            <label>질문</label>
            <input
              className={"form-control"}
              type="text"
              name="title"
              placeholder="제목을 입력해주세요."
              value={title}
              onChange={onChangeFieldValue}
            />
          </div>
          <p className="validation-message ml-90 mb-10">{errors.title}</p>
        </div>
        <div className="mb-20">
          {" "}
          <div className="form-group flex-direction-row align-items-center">
            <label>답변</label>
            <CKEditor
              editor={ClassicEditor}
              data={contents}
              onChange={(event, editor) => {
                const data = editor.getData();
                onChangeContents(data);
              }}
            />
          </div>
          <p className="validation-message ml-90 mb-10">{errors.contents}</p>
        </div>
      </div>

      <div className="divider"></div>
      <div className="editor__footer">
        <div>
          <Link to="/board/faqContents">
            <button className="list-btn">
              <em className="fas fa-list-ul mr-5"></em>
              <strong>목록</strong>
            </button>
          </Link>
        </div>

        <div>
          <button className={`btn btn-s`} onClick={onCreateFaq}>
            <em className="fa fa-check mr-5"></em>
            <strong>FAQ 등록</strong>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateFaqContents;
