import { httpRequest } from "@/util/common";
import React, { Component, Fragment } from "react";
import PlanCompanyList from "./PlanCompanyList";
import PlanModal from "@/commonModule/Modal/PlanModal";
import PlanCompanyDetailContainer from "./fragments/PlanCompanyDetailContainer";

class PlanCompanyListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planMngList: [],
      postsPerPage: 6,
      total: 0,
      chosen: {},
      openModal: false,
      sendCompany: "",
    };
  }

  componentDidMount() {
    this.fetchPlanCompanyList(1);
  }

  render() {
    const { planMngList, postsPerPage, total, chosen, openModal, sendCompany } = this.state;
    return (
      <Fragment>
        <PlanCompanyList
          onChangeFieldValue={this.handleChangeFieldValue}
          currentPosts={planMngList}
          totalPosts={total}
          postsPerPage={postsPerPage}
          onFetchPage={this.fetchPlanCompanyList}
          hasChosen={Object.values(chosen).length}
          onClickModal={this.handleClickModal}
          sendCompany={sendCompany}
        />
        <PlanModal
          id={"detailPreview"}
          isOpen={openModal}
          title={"요금제 현황 상세"}
          className={"bg-primary"}
          style={{ width: 1300, height: '100%' }}
          onClickCloseModal={this.handleClickCloseModal}
        >
          <PlanCompanyDetailContainer
            chosen={chosen}
            sendCompany={sendCompany}
            onClickCloseModal={this.handleClickCloseModal}
            history={this.props.history}
          />
        </PlanModal>
      </Fragment>
    );
  }

  /**
   * 요금제 정보 가져오기
   */
  fetchPlanCompanyList = async (number) => {
    const { postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `plan/company?page=${number}&size=${postsPerPage}`
      );
      this.setState({ ...this.state, planMngList: items, total });
    } catch (err) {
      console.log(err);
      //history.push("/error");
    }
  };

  /**
   * 상세보기 팝업 열기
   */
  handleClickModal = async (post, number) => {
    console.log(post);
    const selCompany = post.companyParam;
    const sendCompany = post.company;
    const selPlan = post.plan_id;

    try {
      const { history } = this.props;
      const { 
        data:{ items },
      } = await httpRequest.get(
        `plan/companyInfo?company=${selCompany}&planId=${selPlan}`
      );

      this.setState({ ...this.state, chosen: items, sendCompany: sendCompany }, () => {
        this.handleClickOpenModal();
      });
      

    } catch (err) {
      this.props.history.push(`/error?code=${err}`);
    }
  };


  /**
   * 상세보기 팝업 열기
   */
  handleClickOpenModal = () => {
    document.body.style.overflow = "hidden";
    this.setState({
      openModal: true,
    });
  };

  /**
   * 상세보기 팝업 닫기
   */
  handleClickCloseModal = () => {
    document.body.style.overflow = "unset";
    this.setState({
      openModal: false,
    });
  };
}

export default PlanCompanyListContainer;
