import axios from "axios";
/**
 * 서버로 에러메세지 발송(첫번째 에러만 발송)
 *
 * @param {string} type
 * @param {string} message
 */
let timer = null;
let errors = [];
export function sendErrorMessage(type, message) {
  clearTimeout(timer);
  errors.push({ type, message });
  timer = setTimeout(() => {
    console.error("에러 전송");
    console.error(errors[0].type, ": ", errors[0].message);
    // 서버로 에러로그 전송. axios.post(url, data, option)...
    errors = [];
    clearTimeout(timer);
  }, 300);
}

/**
 * 동적 스크립트 추가
 * @param src
 * @param async
 */
export function dynamicIncludeScript({ src, async }) {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = src;
  script.async = async;
}
/**
 * 표준시를 yyyy-MM-DD로 변경하는 함수.
 */
export function convertDateFormat(date) {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;
  var day = date.getDate();
  day = day >= 10 ? day : "0" + day;
  return [year, month, day].join("");
}

/**
 * 목표 섹션 위치로 스크롤
 * @param sectionId
 */
export function autoWindowScroll(sectionId) {
  const sectionBCR = document.getElementById(sectionId).getBoundingClientRect();

  window.scroll({
    top: window.scrollY + sectionBCR.top - 80,
    left: 0,
    behavior: "smooth",
  });
}

/**
 * 숫자 3자리마다 콤마 추가
 * ex) 123456789 => 123,456,789
 * @param number
 * @returns {string}
 */
export function commafy(number) {
  return number && number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

/**
 * axios 인스턴스
 */

export function updateHttpRequest() {
  let token;

  try {
    token = sessionStorage.getItem('id_token');
  } catch (error) {
    sendErrorMessage("sessionStorageError", error.message);
  }

  // 인스턴스의 Authorization 헤더 업데이트
  if (token) {
    httpRequest.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    delete httpRequest.defaults.headers['Authorization'];
  }
}

export const httpRequest = axios.create({
  baseURL: ip_config.baseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "K-Host": ip_config.kHost,
    "Api-Key": "bd39d5cf-4f7f-4cc7-a773-28b3100b", // IP킹콩
    "Cache-Control":"no-cache",
  },
  validateStatus: (status) => {
    return status >= 200 && status < 300;
  },
});

httpRequest.defaults.timeout = 900000;


httpRequest.interceptors.response.use(
  (response) => {
    // 2xx 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 데이터가 있는 작업 수행
    return response;
  },
  (error) => {
    console.log("여기로오니" + JSON.stringify(error))
    // 2xx 외의 범위에 있는 상태 코드는 이 함수를 트리거 합니다.
    // 응답 오류가 있는 작업 수행

    if (
      error.response.status === 401 || error.response.status === 403
    ) {
      delete httpRequest.defaults.headers.common['Token'];
      document.cookie = "Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
    }
    return Promise.reject(error);
  }
);

export function logout(){
  delete httpRequest.defaults.headers.common['Token'];
  document.cookie = "Token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  sessionStorage.removeItem(`ipkk_user`);
  sessionStorage.removeItem(`id_token`);
}

/**
 * 월 라디오버튼 정보
 */
export const MONTH_TERM_INFO = {
  THREE_MONTH: {
    label: "3개월",
    monthTerm: "3",
  },
  SIX_MONTH: {
    label: "6개월",
    monthTerm: "6",
  },
};

/**
 * 페이지네이션 버튼 active 스킨 초기화하기
 */
export const handleResetPaginationActive = () => {
  const $container = document.querySelector(".pagination-container");
  const $paginationUl = $container.querySelector("ul");

  if ($paginationUl) {
    const $lists = $paginationUl.querySelectorAll("li");
    if ($lists) {
      $lists.forEach((list) => {
        list.classList.remove("active");
      });
      $lists[1].classList.add("active");
      $lists[1].querySelector("a").click();
    }
  }
};

/**
 * 번역상태에 따른 뱃지 스타일링하기
 * @param {*} transState
 * @returns
 */
export function pickBadgeStyle(transState) {
  switch (transState) {
    case "H":
      return "badge-secondary";
    case "A":
      return "badge-info";
    case "W":
      return "badge-warning";
    case "P":
      return "badge-primary";
    case "E":
      return "badge-success";
    default:
      break;
  }
}

/**
 * 이용안내 스크롤 이동
 */
export function scrollMove(id) {
  var h = document.getElementById(id).parentElement.scrollIntoView(
    { behavior: 'smooth', block: "center" }
  );
}

/**
 * 휴대폰 자리마다 하이픈 추가
 * ex) 01012345678 => 010-1234-5678
 * @param number
 * @returns {string}
 */
export function addNumberHypen(number) {
  let phoneNumber = "";
  const length = number.length;
  if (length > 9) {
    let numbers = number.replace(/[^0-9]/g, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
    phoneNumber = numbers;
  } else {
    phoneNumber = "";
  }
  return phoneNumber;
}

/**
 * 표준시를 yyyy-MM로 변경하는 함수.
 */
export function convertMonthFormat(date, type) {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  month = month >= 10 ? month : "0" + month;
  var result = "";
  if (type === "dot") {
    result = [year, ".", month].join("");
  } else {
    result = [year, month].join("");
  }
  return result;
}