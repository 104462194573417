import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import CreateBoardContents from "./CreateBoardContents";

class CreateBoardContentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardIdx: 1,
      publishYn: "Y",
      noticeYn: "Y",
      title: "",
      contents: "",
      files: [],
      errors: {
        title: "",
        contents: "",
      },
    };
  }

  render() {
    const { boardIdx, publishYn, noticeYn, title, contents, files, errors } =
      this.state;

    return (
      <CreateBoardContents
        boardIdx={boardIdx}
        publishYn={publishYn}
        noticeYn={noticeYn}
        title={title}
        contents={contents}
        files={files}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onChangeContents={this.handleChangeContents}
        onChangeFiles={this.handleChangeFiles}
        onRemoveFiles={this.handleRemoveFiles}
        onCreateBoard={this.handleCreateBoard}
      />
    );
  }

  /**
   * 게시판타입,게시여부,공지여부,제목 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * ckEditor 내용 변경하기
   * @param {*} data
   */
  handleChangeContents = (data) => {
    this.setState({ ...this.state, contents: data });
  };

  /**
   * 첨부파일 핸들러
   * @param {*} e
   */
  handleChangeFiles = (e) => {
    const files = [...this.state.files, e.target.files[0]];
    this.setState({ ...this.state, files });
  };

  /**
   * 첨부파일 뱃지 x버튼 핸들러
   * @param {*} file
   */
  handleRemoveFiles = (file) => {
    const files = this.state.files.filter(
      (v) => v.lastModified !== file.lastModified
    );
    this.setState({ ...this.state, files });
  };

  /**
   * 유효성검사하기
   * @returns
   */
  validate = () => {
    let validated = true;
    const errors = {};
    const { title, contents } = this.state;

    if (!title) {
      errors.title = "제목을 입력해주세요";
      validated = false;
    }
    if (!contents) {
      errors.contents = "내용을 입력해주세요";
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 게시글 등록 요청하기
   */
  handleCreateBoard = async () => {
    const { publishYn, noticeYn, title, contents, files } = this.state;
    if (contents.length > 20000) {
      alert("20,000자 이하로 입력해주세요.");
      return;
    }
    if (this.validate()) {
      try {
        const formData = new FormData();
        formData.append("publishYn", publishYn);
        formData.append("noticeYn", noticeYn);
        formData.append("title", title);
        formData.append("contents", contents);
        files.forEach((file) => {
          formData.append("files", file);
        });

        await httpRequest.post(`board/news`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("정상적으로 등록되었습니다.");
        this.props.history.push("/board/boardContents");
      } catch (err) {
        alert("게시물 등록에 실패하였습니다.");
      }
    }
  };
}

export default CreateBoardContentsContainer;
