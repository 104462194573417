import { handleResetPaginationActive } from "@/util/common";
import React from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import "./BoardContentsList.scss";

const BoardContentsList = ({
  boardIdx,
  searchTitle,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">게시판관리 - 일반게시판</div>
      </div>
      <div className="page-contents">
        <div className="board-contents-list__search-form mb-20">
          <select
            className="select-box mr-20"
            id="boardIdx"
            name="boardIdx"
            value={boardIdx}
            onChange={onChangeFieldValue}
          >
            <option value={1}>새소식</option>
          </select>

          <div className="input-chaining">
            <input
              className={"form-control"}
              type="text"
              name="searchTitle"
              placeholder="검색할 제목을 입력해주세요."
              value={searchTitle}
              onChange={onChangeFieldValue}
            />
            <div className="append">
              <button
                id="search"
                className={"btn btn-s h-full border-0"}
                type="button"
                onClick={() => {
                  onFetchPage(1);
                  handleResetPaginationActive();
                }}
              >
                조회
              </button>
            </div>
          </div>
          <button className={`btn btn-s`}>
            <Link to="/board/view/createBoardContents">게시글 등록</Link>
          </button>
        </div>
        <div className="table-pagination-container ">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>제목</th>
                  <th>작성자</th>
                  <th>작성일</th>
                  <th>게시여부</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={4}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`boardContents-tbody-${i}`}>
                      <td className="text-align-start w-half">
                        <Link
                          to={{
                            pathname: `/board/boardContents/${post.board_contents_idx}`,
                          }}
                          className="text-kk text-bold font-size-15"
                        >
                          {post.title}
                        </Link>
                      </td>
                      <td>{post.reg_user_name}</td>
                      <td>{post.reg_dttm}</td>
                      <td>{post.publish_yn}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardContentsList;
