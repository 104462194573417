import React from "react";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditInquiryContents = ({
  boardIdx,
  boardContentsIdx,
  commentIdx,
  onChangeFieldValue,
  onModComment,
  comment,
}) => {

  return (
    <div className="card card-border-top w-full mt-20 mb-20">
      <div className="editor__header">
        <span>코멘트 수정</span>
      </div>
      <br />
      <div className="input-chaining">
        <textarea
          className={"form-control"}
          rows="5"
          id="comment"
          name="comment"
          placeholder="코멘트를 입력해주세요."
          value={comment}
          onChange={onChangeFieldValue}
          style={{ width: '92%' }}
        />
        <div className="append">
          <button
            id="regComment"
            className={`btn btn-l h-full border-0 bg-kk`}
            type="button"
            onClick={() => onModComment(boardIdx, boardContentsIdx, commentIdx)}
          >
            <b>수정</b>
          </button>
        </div>
      </div>
      <div className="divider"></div>
      <div className="editor__footer">
        <div>
          <Link to="/board/boardContents">
            <button className="list-btn">
              <em className="fas fa-list-ul mr-5"></em>
              <strong>목록</strong>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EditInquiryContents;
