import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import BoardContentsList from "./BoardContentsList";

class BoardContentsListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardIdx: 1,
      searchTitle: "",
      boardContents: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  componentDidMount() {
    this.fetchBoardContents();
  }

  render() {
    const { boardIdx, searchTitle, boardContents, postsPerPage, total } =
      this.state;
    return (
      <BoardContentsList
        boardIdx={boardIdx}
        searchTitle={searchTitle}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={boardContents}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchBoardContents}
      />
    );
  }

  /**
   * BoardContents 리스트 받아오기
   */
  fetchBoardContents = async (number = 1) => {
    const { searchTitle, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `board/news?page=${number}&size=${postsPerPage}&title=${searchTitle}`
      );
      this.setState({ ...this.state, boardContents: items, total });
    } catch (err) {
      history.push("/error");
    }
  };

  /**
   * 검색제목 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default BoardContentsListContainer;
