import React from "react";
import { Link } from "react-router-dom";
import Calendar from "@/commonModule/Calendar/Calendar";
import RadioBtn from "@/commonModule/RadioBtn/RadioBtn";
import { handleResetPaginationActive, commafy } from "@/util/common";
import ReactPaginate from "react-paginate";
import "./PaymentMngList.scss";

const PaymentMngList = ({
  monthTermList,
  onChangeMonthTerm,
  fromDate,
  endDate,
  setFromDate,
  setEndDate,
  userEmail,
  userName,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">결제현황</div>
      </div>
      <div className="page-contents">
        <div className="charge-mng__search-form-top mb-5">
          {" "}
          <RadioBtn
            name={`monthTerm`}
            btnList={monthTermList}
            className={`mr-20 month-term-radio`}
            callback={onChangeMonthTerm}
          />
          <Calendar
            fromDate={fromDate}
            setFromDate={setFromDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
        <div className="d-flex mb-20">
          <input
            className={"form-control w-one-five mr-10"}
            type="text"
            name="userEmail"
            placeholder="사용자 이메일"
            value={userEmail}
            onChange={onChangeFieldValue}
          />
          <input
            className={"form-control w-one-three mr-10"}
            type="text"
            name="userName"
            placeholder="사용자 명"
            value={userName}
            onChange={onChangeFieldValue}
          />
          <button
            className="btn btn-s"
            onClick={() => {
              onFetchPage(1);
              handleResetPaginationActive();
            }}
          >
            조회
          </button>
        </div>
        <div className="table-pagination-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>결제구분</th>
                  <th>결제일시</th>
                  <th>회원ID</th>
                  <th>결제수단</th>
                  <th>결제금액</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={6}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`userMngList-tbody-${i}`}>
                      <td><span>{post.pay_type_nm}</span></td>
                      <td><span>{post.payment_date}</span></td>
                      <td>
                        <Link
                          to={`/user/userMng/${post.user_idx}`}
                          className="text-primary text-bold">
                          {post.email}
                        </Link>
                      </td>
                      
                      <td><span>{post.pay_method_nm}</span></td>
                      <td><span>{post.currency} {commafy(post.amount)}</span></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMngList;
