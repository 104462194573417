import React from "react";
import { Link } from "react-router-dom";
import MonthCalendar from "@/commonModule/Calendar/MonthCalendar";
import { handleResetPaginationActive, commafy } from "@/util/common";
import ReactPaginate from "react-paginate";
import "./PaymentMonthMngList.scss";

const PaymentMonthMngList = ({
  fromDate,
  endDate,
  setFromDate,
  setEndDate,
  userEmail,
  userName,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">월별 결제현황</div>
      </div>
      <div className="page-contents">
        <div className="charge-mng__search-form-top mb-5">
          {" "}
          <MonthCalendar
            fromDate={fromDate}
            setFromDate={setFromDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          <input
            className={"form-control w-one-three mr-10"}
            type="text"
            name="userName"
            placeholder="회사명"
            value={userName}
            onChange={onChangeFieldValue}
          />
          <button
            className="btn btn-s"
            onClick={() => {
              onFetchPage(1);
              handleResetPaginationActive();
            }}
          >
            조회
          </button>
        </div>
        <div className="table-pagination-container">
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>결제월</th>
                  <th>회사명</th>
                  <th>결제금액</th>
                </tr>
              </thead>
              <tbody>
                {currentPosts && currentPosts.length === 0 && (
                  <tr>
                    <td colSpan={4}>검색결과가 없습니다.</td>
                  </tr>
                )}
                {currentPosts &&
                  currentPosts.length > 0 &&
                  currentPosts.map((post, i) => (
                    <tr key={`userMngList-tbody-${i}`}>
                      <td><span>{post.payment_date}</span></td>
                      <td><span>{post.company}</span></td>
                      <td><span>{commafy(post.amount)}</span></td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={(e) => {
                onFetchPage(e.selected + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(totalPosts / postsPerPage)}
              previousLabel="< prev"
              renderOnZeroPageCount={null}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMonthMngList;
