import { handleResetPaginationActive } from "@/util/common";
import React from "react";
import { Link } from "react-router-dom";
import ProgressSheet from "./fragments/ProgressSheet";
import "./TransProgress.scss";

const TransProgress = ({
  overview,
  onFetchOverview,
  error,
  progressType,
  currentPosts,
  totalPosts,
  postsPerPage,
  onClickState,
}) => {
  return (
    <div className="page-container">
      <div className="page__header">
        <div className="page__title">번역현황요약</div>
      </div>
      <div className="page-contents">
        <div className="d-flex justify-content-center w-full mb-20">
          <div className="trans-progress-card">
            <div className="trans-progress-card__number">
              <span>
                <Link
                  to="#"
                  onClick={() => {
                    onClickState("ready");
                    handleResetPaginationActive();
                  }}
                >
                  {overview.ready_state}
                </Link>
              </span>
              건<em className="fas fa-sync-alt" onClick={onFetchOverview}></em>
            </div>
            <div className="text-align-center">
              <span className="badge badge-warning">대기</span>
            </div>
          </div>
          <div className="trans-progress-card">
            <div className="trans-progress-card__number">
              <span>
                <Link
                  to="#"
                  onClick={() => {
                    onClickState("ongoing");
                    handleResetPaginationActive();
                  }}
                >
                  {overview.ongoing_state}
                </Link>
              </span>
              건<em className="fas fa-sync-alt" onClick={onFetchOverview}></em>
            </div>
            <div className="text-align-center">
              {" "}
              <span className="badge badge-primary">진행 중</span>
            </div>
          </div>
          <div className="trans-progress-card">
            <div className="trans-progress-card__number">
              <span>
                <Link
                  to="#"
                  onClick={() => {
                    onClickState("end_today");
                    handleResetPaginationActive();
                  }}
                >
                  {overview.end_today_state}
                </Link>
              </span>
              건<em className="fas fa-sync-alt" onClick={onFetchOverview}></em>
            </div>
            <div className="text-align-center">
              {" "}
              <span className="badge badge-success">금일완료</span>
            </div>
          </div>
          <div className="trans-progress-card">
            <div className="trans-progress-card__number">
              <span>
                <Link
                  to="#"
                  onClick={() => {
                    onClickState("delay");
                    handleResetPaginationActive();
                  }}
                >
                  {overview.delay_state}
                </Link>
              </span>
              건<em className="fas fa-sync-alt" onClick={onFetchOverview}></em>
            </div>
            <div className="text-align-center">
              {" "}
              <span className="badge badge-danger">납품기한 초과</span>
            </div>
          </div>
          <div className="trans-progress-card">
            <div className="trans-progress-card__number">
              <span>
                <Link
                  to="#"
                  onClick={() => {
                    onClickState("fail");
                    handleResetPaginationActive();
                  }}
                >
                  {overview.fail_state}
                </Link>
              </span>
              건<em className="fas fa-sync-alt" onClick={onFetchOverview}></em>
            </div>
            <div className="text-align-center">
              {" "}
              <span className="badge badge-danger">번역요청 실패</span>
            </div>
          </div>
        </div>

        {error && (
          <div className="validation-message text-align-center font-size-20 mb-20 ">
            {error}
          </div>
        )}

        <ProgressSheet
          progressType={progressType}
          currentPosts={currentPosts}
          totalPosts={totalPosts}
          postsPerPage={postsPerPage}
          onClickState={onClickState}
        />
      </div>
    </div>
  );
};

export default TransProgress;
