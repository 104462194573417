import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import PlanMngList from "./PlanMngList";

class PlanMngListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planType: "",
      userEmail: "",
      userName: "",
      planMngList: [],
      postsPerPage: 6,
      total: 0,
      planTotalCnt: [],
    };
  }

  componentDidMount() {
    this.fetchPlanMngList(1);
  }

  render() {
    const { planType, userEmail, userName, planMngList, postsPerPage, total, planTotalCnt } = this.state;
    return (
      <PlanMngList
        planType={planType}
        userEmail={userEmail}
        userName={userName}
        currentPlan={planTotalCnt}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={planMngList}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchPlanMngList}
      />
    );
  }

  /**
   * 청구 정보 가져오기
   */
  fetchPlanMngList = async (number) => {
    const { planType, userEmail, userName, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total, plans },
      } = await httpRequest.get(
        `plan?page=${number}&size=${postsPerPage}&mail=${userEmail}&name=${userName}&planType=${planType}`
      );
      this.setState({ ...this.state, planMngList: items, total, planTotalCnt: plans });
    } catch (err) {
      console.log(err);
      //history.push("/error");
    }
  };

  /**
   * 사용자이메일, 사용자명, 요금제 인풋, select 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}

export default PlanMngListContainer;
