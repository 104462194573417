import { httpRequest , updateHttpRequest} from "@/util/common";
import React, { Component } from "react";
import TransProgress from "./TransProgress";

class TransProgressInfoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overview: {},
      error: null,
      chosen: { progressType: null, datum: [], total: 0 },
      postsPerPage: 6,
    };
  }

  componentDidMount() {
    this.fetchOverview();
  }

  render() {
    const {
      overview,
      error,
      chosen: { progressType, datum, total },
      postsPerPage,
    } = this.state;
    return (
      <TransProgress
        overview={overview}
        onFetchOverview={this.fetchOverview}
        error={error}
        progressType={progressType}
        currentPosts={datum}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onClickState={this.handleClickState}
      />
    );
  }

  /**
   * 번역현황요약 count 가져오기
   */
  fetchOverview = async () => {
    try {
      const { data: overview } = await httpRequest.get("translate/progress");
      this.setState({ ...this.state, overview });
      console.log("여기다"+overview)
    } catch (err) {
      this.setState({
        error: "번역현황요약 정보를 가져올 수 없습니다.",
      });
    }
  };

  /**
   * 상태별 건수 클릭해서 상태별 정보 가져오기
   * @param {*} progressType
   * @param {*} number
   */
  handleClickState = async (progressType, number = 1) => {
    const { postsPerPage } = this.state;
    switch (progressType) {
      case "ready": {
        const {
          data: { items, total },
        } = await httpRequest.get(
          `translate?page=${number}&size=${postsPerPage}&transState=W`
        );
        this.setState({
          ...this.state,
          chosen: { progressType, datum: items, total },
        });
        break;
      }

      case "ongoing": {
        const {
          data: { items, total },
        } = await httpRequest.get(
          `translate?page=${number}&size=${postsPerPage}&transState=P`
        );
        this.setState({
          ...this.state,
          chosen: { progressType, datum: items, total },
        });
        break;
      }

      case "end_today": {
        const {
          data: { items, total },
        } = await httpRequest.get(
          `translate?page=${number}&size=${postsPerPage}&transState=E`
        );
        this.setState({
          ...this.state,
          chosen: { progressType, datum: items, total },
        });
        break;
      }

      case "delay": {
        const {
          data: { items, total },
        } = await httpRequest.get(
          `translate?page=${number}&size=${postsPerPage}&transState=X`
        );
        this.setState({
          ...this.state,
          chosen: { progressType, datum: items, total },
        });
        break;
      }

      case "fail": {
        const {
          data: { items, total },
        } = await httpRequest.get(
          `translate/fail?page=${number}&size=${postsPerPage}`
        );
        this.setState({
          ...this.state,
          chosen: { progressType, datum: items, total },
        });
        break;
      }

      default:
        break;
    }
  };
}

export default TransProgressInfoContainer;
