import React from "react";
import { Link } from "react-router-dom";
import "./InquiryDtl.scss";
import parse from "html-react-parser";

const InquiryDtl = ({ post, postComment, attachFile, onFileDownload, commentVal, onChangeFieldValue, regComment, modComment, onDeleteComment }) => {
  return (
    <div className="page-container">
      <div className="page__title">1:1 문의 상세</div>
      <div className="divider"></div>
      <div className="card w-full">
        <small>{post.faq_type_name}</small>
        <div className="board-news-dtl__header">
          <div>
            {post.title}
            <span style={{ color: Number(post.commentCnt) > 0 ? '#5d9cec' : '#121212' }}>({
              // 답변여부
              (() => {
                const commentCnt = Number(post.comment_cnt);
                let statusMessage = "";
                if (commentCnt > 0) {
                  statusMessage = "답변완료";  // 계산식
                } else if (commentCnt === 0) {
                  statusMessage = "답변대기";
                } else {
                  statusMessage = "-";
                }
                return statusMessage;
              })()
            })</span>
          </div>
          <div>
            <span>
              <strong>작성자</strong>
              {post.reg_user_name}
              &nbsp;&nbsp;
              <strong>작성일시</strong>
              {post.reg_dttm_time}
            </span>
          </div>
        </div>
        <div className="divider"></div>
        <div className={"mb-30"}>
          <div className="board-news-dtl__main">{parse(String(post.contents))}</div>
          <div className="attach-file">
            첨부파일&nbsp;
            {attachFile &&
              attachFile.length > 0 &&
              attachFile.map((v, i) => (
                <div key={`edit-attach-file-${i}`} className="attach-file__item">
                  <Link
                    to="#"
                    onClick={() => onFileDownload(v.file_idx, v.org_file_name)}
                  >
                    {v.org_file_name}
                  </Link>
                </div>
              ))}
          </div>
        </div>  
        <div className="divider"></div>
          <div className="input-chaining">
            <textarea
              className={"form-control"}
              rows="5"
              id="commentVal"
              name="commentVal"
              placeholder="코멘트를 입력해주세요."
              value={commentVal}
              onChange={onChangeFieldValue}
              style={{width: '92%'}}
            />
            <div className="append">
              <button
                id="regComment"
                className={`btn btn-l h-full border-0 bg-kk`}
                type="button"
                onClick={() => regComment(post.boardIdx, post.boardContentsIdx)}
              >
                <b>등록</b>
              </button>
            </div>
          </div>
          {postComment && postComment.length > 0 && (
            postComment.map((v, i) => (
            <div className="card w-full" key={`dtl-comment-${i}`}>
              <div className="board-news-dtl__header">{v.userName}({v.reg_dttm_time})</div>
              <br />
              <div className="board-news-dtl__main__sub">{v.comment}</div>
              <div className="editor__footer__btn">
                <div className={`d-flex`}>
                    <Link 
                      to={{
                        pathname: `/board/inquiryContents/${v.board_contents_idx}_${v.comment_idx}`,
                      }}>
                      <button className={`btn btn-s`}>
                        <em className="fa fa-check mr-5"></em>
                        <strong>수정</strong>
                      </button>
                    </Link>
                    &nbsp;
                  <button 
                    className={`btn btn-s bg-danger`}
                    onClick={() => onDeleteComment(v.board_contents_idx, v.comment_idx)}
                  >
                    <em className="fa fa-times mr-5"></em>
                    <strong>삭제</strong>
                  </button>
                </div>
              </div>  
            </div>   
          )))}
        <div className="divider"></div>
        <div className="editor__footer">
          <div>
            <Link to="/board/inquiry">
              <button className="list-btn">
                <i className="fas fa-list-ul"></i>
                <span>목록</span>
              </button>
            </Link>
          </div>
          {/* 
          <div className="d-flex">
            <Link to={`/board/boardContents/${post.board_contents_idx}`}>
              <button className="btn btn-s">
                <em className="fa fa-check mr-5"></em>
                <strong>수정</strong>
              </button>
            </Link>
          </div>
          */}
        </div>
      </div>
    </div>
  );
};

export default InquiryDtl;
